import firebase from 'firebase';

import API from './API';

const AuthVerification = {
    isAuthenticated() {
        return localStorage.getItem("token") != null
    },
    async authenticate() {
        try {
            let idToken = await firebase.auth().currentUser.getIdToken(true);
            localStorage.setItem("token", idToken)

            var resp = await API.authorizedJSONPost("/api/account/verify", {});
            if (resp.ok) {
                const loginUser = await resp.json()
                localStorage.setItem("loginUser", JSON.stringify(loginUser))
                return true;
            }
        } catch (e) {
            //error
            console.log(e)
        }

        await this.signout();
        return false;
    },
    async signout() {
        localStorage.removeItem("loginUser")
        localStorage.removeItem("token")
        await firebase.auth().signOut()
    },
    async token() {
        //refresh token
        if (firebase.auth().currentUser != null) {
            firebase.auth().currentUser.getIdToken(true)
                .then(function (idToken) {
                    localStorage.setItem("token", idToken)
                }).catch(function (error) {
                    localStorage.removeItem("token")
                    localStorage.removeItem("loginUser")
                });
        }
        return localStorage.getItem("token")
    },
    currentUser() {
        return JSON.parse(localStorage.getItem("loginUser"))
    }
};

export default AuthVerification