import React, { Component, Fragment } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import API from '../../API';
import AlertBlockUI from '../shared/AlertBlockUI';
import DeleteDialog from '../shared/DeleteDialog';
import Paging from '../shared/Paging';
import { handleError } from '../shared/ResponseHandler';
import TableToolbar from '../shared/TableToolbar';
import CourseSectionAdd from './CourseSectionAdd';
import CourseSectionLessonAdd from './CourseSectionLessonAdd';

class CourseSection extends React.Component {
    constructor(props) {
        super(props)
        const { match } = this.props;
        var courseId = match.params.id;

        this.state = {
            status: 'new',
            courseId: courseId,
            //search lesson
            start: 0,
            length: 10,
            keyword: "",
            sortBy: {
                column: "sortOrder",
                dir: "asc"
            },
            response: { recordsTotal: 0, recordsFiltered: 0, data: [] }
        }
    }

    componentDidMount() {
        this.loadData()
    }

    loadData = async () => {
        var resp = await API.authorizedJSONPost("/api/teacher/course-section/search", this.state);

        if (resp.ok) {
            var json = await resp.json();
            this.setState({ response: json })
        } else {
            handleError(resp, this)
        }
    }

    search = async (keyword) => {
        this.setState({ keyword, start: 0 }, this.loadData)
    }

    navigate = async (page, length) => {
        if (page < 1) {
            page = 1
        }
        const nextStart = (page - 1) * this.state.length
        this.setState({ start: nextStart, length }, this.loadData)
    }

    deleteItem = async (ids) => {
        var resp = await API.authorizedJSONPost("/api/teacher/course-section/delete", ids);
        if (resp.ok) {
            this.setState({ status: "ok" })
            this.loadData()
        } else {
            handleError(resp, this)
        }
    }

    render() {
        let { t } = this.props;
        return (
            <div className="content-wrapper">
                <Breadcrumb />
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-sm-4">
                                <CourseSectionAdd loadData={this.loadData} />
                                <CourseSectionLessonAdd loadData={this.loadData} />
                            </div>
                            <div className="col-12 col-sm-8">
                                <div className="card card-secondary">
                                    <TableToolbar search={this.search} title={t('admin:course.courseSection')} />
                                    <DataTable {...this.props} {...this.state} deleteItem={this.deleteItem} />
                                    <Paging {...this.props} {...this.state} navigate={this.navigate} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <AlertBlockUI {...this.state} setState={(status) => this.setState(status)} />
            </div>
        )
    }
}
const Breadcrumb = () => {
    const { t } = useTranslation();
    return <section className="content-header">
        <div className="container-fluid">
            <div className="row mb-2">
                <div className="col-sm-6">
                    <h1><Link to="/admin/course"><i className="fas fa-arrow-left fa-sm" /> </Link> {t('admin:course.courseSection')}</h1>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/admin">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/admin/course">{t('admin:meta.course.title')}</Link></li>
                        <li className="breadcrumb-item active">{t('admin:course.courseSection')}</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
}

class DataTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedDeletionItemId: null
        }
    }

    render() {
        let { t } = this.props;
        return <div className="card-body table-responsive p-0">
            <table className="table table-hover text-nowrap">
                <thead>
                    <tr>
                        <th>#</th>
                        <th >{t('admin:course.courseSection.courseSectionName')}</th>
                        <th >{t('admin:course.courseSection.sortOrder')}</th>
                        <th>{t('admin:button.action')}</th>
                    </tr>
                </thead>
                <tbody >
                    {this.props.response.data.map((item) => {
                        return <Fragment key={item.id}>
                            <tr >
                                <td> {item.id} </td>
                                <td> {item.name}</td>
                                <td> {item.sortOrder}</td>
                                <td>
                                    <div>
                                        <Link className="btn btn-info btn-sm" to={'/admin/course/section/edit/' + item.id}><i className="fas fa-pencil-alt">
                                        </i> {t('button.edit')} </Link>
                                        <button type="button" onClick={() => this.setState({ selectedDeletionItemId: item.id })} className="btn btn-danger btn-sm" data-toggle="modal" data-target="#modal-delete">
                                            <i className="fas fa-trash" /> {t('button.delete')}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan="4">
                                    <CourseSectionLesson t={t} courseSectionId={item.id} />
                                </td>
                            </tr>
                        </Fragment>
                    })}
                </tbody>
            </table>
            <DeleteDialog {...this.state} {...this.props} />
        </div>
    }
}

class CourseSectionLesson extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            status: 'new',
            courseSectionId: this.props.courseSectionId,
            //search lesson
            start: 0,
            length: 10,
            keyword: "",
            sortBy: {
                column: "sortOrder",
                dir: "asc"
            },
            response: { recordsTotal: 0, recordsFiltered: 0, data: [] }
        }
    }

    componentDidMount() {
        this.loadData()
    }

    loadData = async () => {
        var resp = await API.authorizedJSONPost("/api/teacher/course-section-lesson/search ", this.state);

        if (resp.ok) {
            var json = await resp.json();
            this.setState({ response: json })
        } else {
            handleError(resp, this)
        }
    }

    search = async (keyword) => {
        this.setState({ keyword, start: 0 }, this.loadData)
    }

    navigate = async (page, length) => {
        if (page < 1) {
            page = 1
        }
        const nextStart = (page - 1) * this.state.length
        this.setState({ start: nextStart, length }, this.loadData)
    }

    deleteItem = async (ids) => {
        var resp = await API.authorizedJSONPost("/api/teacher/course-section-lesson/delete", ids);
        if (resp.ok) {
            this.setState({ status: "ok" })
            this.loadData()
        } else {
            handleError(resp, this)
        }
    }

    render() {
        let { t } = this.props;
        return (
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12 col-sm-12">
                            <div className="card card-info">
                                <TableToolbar search={this.search} title={t('admin:course.courseSectionLesson')} />
                                <CourseSectionLessonDataTable {...this.props} {...this.state} deleteItem={this.deleteItem} />
                                <Paging {...this.props} {...this.state} navigate={this.navigate} />
                            </div>
                        </div>
                    </div>
                </div>
                <AlertBlockUI {...this.state} setState={(status) => this.setState(status)} />
            </section>
        )
    }
}


class CourseSectionLessonDataTable extends Component {
    constructor(props) {
        super(props)
    }

    render() {
        let { t } = this.props;
        return <div className="card-body table-responsive p-0">
            <table className="table table-hover text-nowrap">
                <thead>
                    <tr>
                        <th>#</th>
                        <th >{t('admin:course.courseSectionLesson.lesson')}</th>
                        <th >{t('admin:course.courseSection.sortOrder')}</th>
                        <th>{t('admin:button.action')}</th>
                    </tr>
                </thead>
                <tbody >
                    {this.props.response.data.map((item) => {
                        return <tr key={item.id}>
                            <td> {item.id} </td>
                            <td> {item.lessonDTO.name}</td>
                            <td> {item.sortOrder}</td>
                            <td>
                                <div>

                                    <button type="button" onClick={() => this.props.deleteItem([item.id])} className="btn btn-danger btn-sm">
                                        <i className="fas fa-trash" /> {t('button.delete')}
                                    </button>
                                </div>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    }
}


export default withRouter(withTranslation()(CourseSection))