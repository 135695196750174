import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';


class TableToolbar extends Component {
    constructor(props) {
        super(props)
        this.state = {
            keyword: "",
            role:""

        }
    }
    setKeyword = (event) => {
        this.setState({ keyword: event.target.value })
    }
    setRole = (event) => {
        this.setState({ role: event.target.value })
    }

    search = () => {
        this.props.search(this.state.keyword, this.state.role)
    }
   
    reset = () => {
        this.setState({ keyword: "" }, this.props.search)
    }

    render() {
        let { t } = this.props;
        return <div className="card-header">
            <h3 className="card-title">{this.props.title}</h3>
            <div className="card-tools">
                <div className="input-group input-group-sm" >
                    <input type="text" value={this.state.keyword} name="table_search" className="form-control float-right" placeholder={t('button.search')}
                        onChange={this.setKeyword} />
                     
                    <div className="input-group-append">
                        <button type="button" onClick={this.search} className="btn btn-default"><i className="fas fa-search"></i></button>
                        <button type="button" onClick={this.reset} className="btn btn-default"><i className="fas fa-sync"></i></button>
                    </div>
                </div>
            </div>
        </div>
    }
}


export default (withTranslation()(TableToolbar))