import React from 'react';
import { Redirect } from 'react-router-dom';

import Login from './Login';
import Maintain from './Maintain';
import NotFound from './NotFound';
import AuthVerification from './AuthVerification';

export const AppRoutes = [
    {
        path: "/",
        exact: true,
        main: () => <Redirect to={'/login'} />
    },
    {
        path: "/login",
        exact: true,
        title: 'meta.login.title',
        description: 'meta.login.description',
        main: () => AuthVerification.isAuthenticated() && AuthVerification.currentUser() && AuthVerification.currentUser().roles.indexOf("ROLE_ADMIN") !== -1 ? <Redirect to='/admin' /> : <Login />
    },
    {
        path: "/maintain",
        exact: true,
        title: 'meta.maintain.title',
        description: 'meta.maintain.description',
        main: () => <Maintain />
    },
    //khai bao cac url ben tren * de tranh goi notfound trc
    {
        path: "*",
        title: 'meta.404.title',
        description: 'meta.404.description',
        main: () => <NotFound />
    }
]