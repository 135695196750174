import React, { Component } from 'react';
import AuthVerification from '../../AuthVerification';

//pass obj this to
export async function handleError(resp, obj) {
    if (resp.status === 401 || resp.status === 403) {
        await AuthVerification.signout()
        const { history } = obj.props
        history.replace("/login")
    } else {
        obj.setState({ status: "error" });
        if (resp.status === 409) {
            obj.setState({ message: 'message.error.conflict' });
        }
        if (resp.status === 500) {
            obj.setState({ message: 'message.error.server' });
        }
        if (resp.status === 400) {
            obj.setState({ message: 'message.error.badrequest' });
        }
    }
}