import { Editor } from '@tinymce/tinymce-react';
import React, { Component } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import AlertBlockUI from '../shared/AlertBlockUI';
import API from '../../API';
import { handleError } from '../shared/ResponseHandler';
import { tinyConfig } from '../shared/TinyConfig';
import getSlug from 'speakingurl';
import AuthVerification from '../../AuthVerification';

class LessonCommentEdit extends React.Component {

    render() {
        return (<div className="content-wrapper">
            <Breadcrumb />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-secondary">
                                <FormHeader />
                                <FormBody {...this.props} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        )
    }
}

const Breadcrumb = () => {
    const { t } = useTranslation();
    return <section className="content-header">
        <div className="container-fluid">
            <div className="row mb-2">
                <div className="col-sm-6">
                    <h1><Link to="/admin/lesson"><i className="fas fa-arrow-left fa-sm" /> </Link>  {t('admin:lesson.edit.title')}</h1>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/admin">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/admin/lesson">{t('admin:meta.lesson.title')}</Link></li>
                        <li className="breadcrumb-item active">{t('admin:lesson.edit.title')}</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
}

const FormHeader = () => {
    const { t } = useTranslation()
    return <div className="card-header">
        <h3 className="card-title">{t('admin:lesson.edit.title')}</h3>
    </div>
}

class FormBody extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: 0,
            content:'',
            status: '',
            lessonId: '',
            accountId:''

        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        const { match } = this.props;
        var id = match.params.id;
        var resp = await API.authorizedJSONGET("/api/lesson-comment/" + id);
        if (resp.ok) {
            var comment = await resp.json();
            this.setState(comment);
        } else {
            handleError(resp, this)
        }
    }

    setContent = (event) => this.setState({ content: event.target.value })
    setStatus = (event) => this.setState({ status: event.target.value })
    setLessonId = (event) => this.setState({ lessonId: event.target.value })
    setAccountId = (event) => this.setState({ accountId: event.target.value })


    submit = async () => {
        var resp = await API.authorizedJSONPut("/api/lesson-comment/update", this.state);
        if (resp.ok) {
            this.setState({ status: "ok" })
        } else {
            handleError(resp, this)
        }
    }



    render() {
        const { t } = this.props;

        return <form id="form">
            <div className="card-body">
                <div className="form-group">
                    <label>#</label>
                    <input type="text" className="form-control" disabled
                        value={this.state.id} />
                </div>


                <div className="form-group">
                    <label >{t('admin:comment.content')}</label>
                    <input type="text" className="form-control" onChange={this.setContent}
                        value={this.state.content} placeholder={t('admin:comment.content')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:comment.status')}</label>
                    <input disabled type="text" className="form-control" onChange={this.setStatus}
                        value={this.state.status} placeholder={t('admin:comment.status')} required />
                </div>

                <div className="form-group">
                    <label >{t('admin:comment.lesson')}</label>
                    <input disabled type="text" className="form-control" onChange={this.setLessonId}
                        value={this.state.lessonId} placeholder={t('admin:comment.lesson')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:comment.user')}</label>
                    <input disabled type="text" className="form-control" onChange={this.setAccountId}
                        value={this.state.accountId} placeholder={t('admin:comment.user')} required />
                </div>


            </div>
            <div className="card-footer">
                <button type="button" onClick={this.submit} className="btn btn-info">{t('button.save')}</button>
            </div>
            <AlertBlockUI {...this.state} setState={(status) => this.setState(status)} />
        </form>
    }
}
export default withRouter(withTranslation()(LessonCommentEdit));




/////
// import React, { Component } from 'react';
// import { useTranslation, withTranslation } from 'react-i18next';
// import { Link, withRouter } from 'react-router-dom';

// import API from '../../API';
// import { handleError } from '../shared/ResponseHandler';
// import AlertBlockUI from '../shared/AlertBlockUI';
// class LessonCommentEdit extends React.Component {

//     render() {
//         return (<div className="content-wrapper">
//             <Breadcrumb />
//             <section className="content">
//                 <div className="container-fluid">
//                     <div className="row">
//                         <div className="col-12">
//                             <div className="card card-secondary">
//                                 <FormHeader />
//                                 <FormBody {...this.props} />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </section>
//         </div>
//         )
//     }
// }

// const Breadcrumb = () => {
//     const { t } = useTranslation();
//     return <section className="content-header">
//         <div className="container-fluid">
//             <div className="row mb-2">
//                 <div className="col-sm-6">
//                     <h1><Link to="/teacher/tag"><i className="fas fa-arrow-left fa-sm" /> </Link>  {t('admin:tag.edit.title')}</h1>
//                 </div>
//                 <div className="col-sm-6">
//                     <ol className="breadcrumb float-sm-right">
//                         <li className="breadcrumb-item"><Link to="/admin">Home</Link></li>
//                         <li className="breadcrumb-item"><Link to="/teacher/tag">{t('admin:meta.tag.title')}</Link></li>
//                         <li className="breadcrumb-item active">{t('admin:tag.edit.title')}</li>
//                     </ol>
//                 </div>
//             </div>
//         </div>
//     </section>
// }

// const FormHeader = () => {
//     const { t } = useTranslation()
//     return <div className="card-header">
//         <h3 className="card-title">{t('admin:tag.edit.title')}</h3>
//     </div>
// }

// class FormBody extends Component {
//     constructor(props) {
//         super(props)
//         this.state = {
//             id: 0,
//             content: '',
//             status: '',
//             lessonId: '',
//             accountId: ''
//         }
//     }

//     componentDidMount() {
//         this.loadData();
//     }

//     loadData = async () => {
//         const { match } = this.props;
//         var id = match.params.id;
//         var resp = await API.authorizedJSONGET("/api/lesson-comment/" + id);
//         if (resp.ok) {
//             var tag = await resp.json();
//             this.setState(tag);
//         } else {
//             handleError(resp, this)
//         }
//     }
//     setContent = (event) => this.setState({ content: event.target.value })
//     setStatus = (event) => this.setState({ status: event.target.value })
//     setLessonId = (event) => this.setState({ lessonId: event.target.value })
//     setAccountId = (event) => this.setState({ accountId: event.target.value })

//     // setTagName = (event) => this.setState({ tagName: event.target.value })

//     submit = async () => {
//         var resp = await API.authorizedJSONPut("/api/lesson-comment/update", this.state);
//         if (resp.ok) {
//             this.setState({ status: "ok" })
//         } else {
//             handleError(resp, this)
//         }
//     }

//     render() {
//         const { t } = this.props;

//         return <form >
//             <div className="card-body">
//                 <div className="form-group">
//                     <label>#</label>
//                     <input type="text" className="form-control"
//                         value={this.state.id} disabled />
//                 </div>
//                 <div className="form-group">
//                     <label >{t('admin:tag.name')}</label>
//                     <input type="text" className="form-control" onChange={this.setTagName}
//                         value={this.state.tagName} placeholder={t('admin:tag.name')} />
//                 </div>
//             </div>
//             <div className="card-footer">
//                 <button type="button" onClick={this.submit} className="btn btn-info">{t('button.save')}</button>
//             </div>
//             <AlertBlockUI {...this.state} setState={(status) => this.setState(status)} />
//         </form>
//     }
// }

// export default withRouter(withTranslation()(LessonCommentEdit));