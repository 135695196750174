import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import AuthVerification from '../../AuthVerification';
import { useHistory } from 'react-router-dom';

function PageMenu() {
    const { t } = useTranslation();
    let history = useHistory();
    return (
        <nav className="main-header navbar navbar-expand-md navbar-light navbar-white">
            <div className="container-fluid">
                <Link to="/admin" className="navbar-brand">
                    <img src="/logo-b.png" className="brand-image" alt="J Master" />
                </Link>

                <button className="navbar-toggler order-1" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse order-3" id="navbarCollapse">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <Link to="/admin" className="nav-link">Home</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">{t('admin:meta.account.title')}</a>
                            <ul aria-labelledby="dropdownSubMenu1" className="dropdown-menu border-0 shadow">
                                <li><Link to="/admin/account" className="dropdown-item">{t('admin:meta.account.title')}</Link></li>
                                <li><Link to="/admin/user-group" className="dropdown-item">{t('admin:meta.userGroup.title')}</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">{t('admin:meta.learn-path.title')}</a>
                            <ul aria-labelledby="dropdownSubMenu1" className="dropdown-menu border-0 shadow">
                                <li><Link to="/admin/learn-path" className="dropdown-item">{t('admin:meta.learn-path.title')}</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">{t('admin:meta.course.title')}</a>
                            <ul aria-labelledby="dropdownSubMenu1" className="dropdown-menu border-0 shadow">
                                <li><Link to="/admin/category" className="dropdown-item">{t('admin:meta.courseCategory.title')}</Link></li>
                                <li className="dropdown-divider"></li>

                                <li><Link to="/admin/level" className="dropdown-item">{t('admin:meta.level.title')}</Link></li>
                                <li className="dropdown-divider"></li>

                                <li><Link to="/admin/tag" className="dropdown-item">{t('admin:meta.tag.title')}</Link></li>
                                <li className="dropdown-divider"></li>

                                <li><Link to="/admin/lesson" className="dropdown-item">{t('admin:meta.lesson.title')}</Link></li>
                                <li className="dropdown-divider" ></li>
                                <li><Link to="/admin/course" className="dropdown-item">{t('admin:meta.course.title')}</Link></li>

                                <li className="dropdown-divider" ></li>
                                <li><Link to="/admin/slide" className="dropdown-item">{t('admin:meta.slide.title')}</Link></li>
                                <li className="dropdown-divider"></li>
                                <li><Link to="/admin/support" className="dropdown-item">{t('admin:meta.support.title')}</Link></li>
                                <li className="dropdown-divider"></li>
                                <li><Link to="/admin/notification" className="dropdown-item">{t('admin:meta.notification.title')}</Link></li>

                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <a id="dropdownSubMenu1" href="#" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="nav-link dropdown-toggle">{t('admin:order.name')}</a>
                            <ul aria-labelledby="dropdownSubMenu1" className="dropdown-menu border-0 shadow">
                                <li><Link to="/admin/order" className="dropdown-item">{t('admin:meta.order.title')}</Link></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <Link to="/admin/feedback" className="nav-link">Góp ý</Link>
                        </li>
                    </ul>

                    <form className="form-inline ml-0 ml-md-3">
                        <div className="input-group input-group-sm">
                            <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                            <div className="input-group-append">
                                <button className="btn btn-navbar" type="submit">
                                    <i className="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
                    <li className="nav-item dropdown">
                        <a className="nav-link" data-toggle="dropdown" href="#">
                            <i className="far fa-bell"></i>
                            <span className="badge badge-warning navbar-badge">15</span>
                        </a>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                            <span className="dropdown-header">15 Notifications</span>
                            <div className="dropdown-divider"></div>
                            <a href="#" className="dropdown-item">
                                <i className="fas fa-envelope mr-2"></i> 4 new messages
              <span className="float-right text-muted text-sm">3 mins</span>
                            </a>
                            <div className="dropdown-divider"></div>
                            <a href="#" className="dropdown-item">
                                <i className="fas fa-users mr-2"></i> 8 friend requests
              <span className="float-right text-muted text-sm">12 hours</span>
                            </a>
                            <div className="dropdown-divider"></div>
                            <a href="#" className="dropdown-item dropdown-footer">See All Notifications</a>
                        </div>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" href="#" onClick={async () => {
                            await AuthVerification.signout();
                            history.replace("/login");
                        }}><i className="fas fa-sign-out-alt"></i></a>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default PageMenu;