// import React, { Component } from 'react';

// import API from '../../API';
// import { handleError } from '../shared/ResponseHandler';
// import { withRouter } from 'react-router-dom';

// class LessonComment extends React.Component {

//     constructor(props) {
//         super(props)
//         this.state = {
//             start: 0,
//             length: 10,
//             keyword: "",
//             sortBy: {
//                 'column': 'id',
//                 'dir': 'desc'
//             },
//             response: { recordsTotal: 0, recordsFiltered: 0, data: [],lessonId:0}
//         }
//     }

//     componentDidMount() {
//         const { match } = this.props;
//         const lessonId = match.params.id;
//         console.log('day la',lessonId);

//         let response = this.state.response;
//         response.lessonId = lessonId;
//         this.setState({ response });
//         this.setState({ lessonId: lessonId }, () => {
//             this.loadData();    
//         });
//         // this.loadData();
//     }

//     loadData = async () => {
//         var resp = await API.authorizedJSONPost("/api/lesson-comment/search", this.state);

//         if (resp.ok) {
//             var json = await resp.json();
//             this.setState({ response: json })
//         } else {
//             handleError(resp, this)
//         }
//     }
//     render() {

//         return <div>
//             <table >
//                 <thead>
//                     <tr>
//                         <th >Content</th>
//                         <th>Status</th>
//                         <th>LessonId</th>

//                     </tr>
//                 </thead>
//                 <tbody >
//                     {this.state.response.data.map((item) => {
//                         return <tr >

//                             <td> {item.content} </td>
//                             <td> {item.status} </td>
//                             <td> {item.lessonId} </td>

//                         </tr>
//                     })}
//                 </tbody>
//             </table>
//         </div>
//     }
// }
// export default withRouter(LessonComment);
import React, { Component } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

import AlertBlockUI from '../shared/AlertBlockUI';
import DeleteDialog from '../shared/DeleteDialog';
import Paging from '../shared/Paging';
import API from '../../API';
import { handleError } from '../shared/ResponseHandler';
import TableToolbar from '../shared/TableToolbar';
class LessonComment extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            start: 0,
            length: 10,
            keyword: "",
            sortBy: {
                'column': 'id',
                'dir': 'desc'
            },
            response: { recordsTotal: 0, recordsFiltered: 0, data: []}
        }
    }

    componentDidMount() {
        const { match } = this.props;
        const lessonId = match.params.id;
        console.log('day la', lessonId);
        this.setState({ lessonId: lessonId }, () => {
            this.loadData();
        });
    }

    loadData = async () => {
        var resp = await API.authorizedJSONPost("/api/lesson-comment/search", this.state);

        if (resp.ok) {
            var json = await resp.json();
            this.setState({ response: json })
        } else {
            handleError(resp, this)
        }
    }

    search = async (keyword) => {
        this.setState({ keyword, start: 0 }, this.loadData)
    }

    navigate = async (page, length) => {
        if (page < 1) {
            page = 1
        }
        const nextStart = (page - 1) * this.state.length
        this.setState({ start: nextStart, length }, this.loadData)
    }

    deleteItem = async (ids) => {
        var resp = await API.authorizedJSONPost("/lesson-comment/delete", ids);
        if (resp.ok) {
            this.setState({ status: "ok" })
            this.loadData()
        } else {
            handleError(resp, this)
        }
    }

    render() {
        let { t } = this.props;
        return (
            <div className="content-wrapper">
                <Breadcrumb />
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">

                            <div className="col-12 col-sm-8">
                                <div className="card card-secondary">
                                    <TableToolbar search={this.search} title={t('admin:meta.comment.title')} />

                                    <DataTable {...this.props} {...this.state} deleteItem={this.deleteItem} />

                                    <Paging {...this.props} {...this.state} navigate={this.navigate} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <AlertBlockUI {...this.state} setState={(status) => this.setState(status)} />
            </div>
        )
    }
}
const Breadcrumb = () => {
    const { t } = useTranslation();
    return <section className="content-header">
        <div className="container-fluid">
            <div className="row mb-2">
                <div className="col-sm-6">
                    <h1>{t('admin:meta.comment.title')}</h1>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/admin">Home</Link></li>
                        <li className="breadcrumb-item active">{t('admin:meta.comment.title')}</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
}

class DataTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedDeletionItemId: null
        }
    }

    render() {
        let { t } = this.props;
        return <div className="card-body table-responsive p-0">
            <table className="table table-hover text-nowrap">
                <thead>
                    <tr>
                        <th>#</th>
                        <th >{t('admin:comment.content')}</th>
                        <th >{t('admin:comment.status')}</th>
                        <th>{t('admin:comment.lesson')}</th>
                        <th>{t('admin:comment.user')}</th>
                        <th>{t('admin:comment.date')}</th>
                        <th>{t('admin:button.action')}</th>


                    </tr>
                </thead>
                <tbody >
                    {this.props.response.data.map((item) => {
                        return <tr key={item.id}>
                            <td> {item.id} </td>
                            <td> {item.content} </td>
                            <td> {item.status} </td>
                            <td> {item.lessonId} </td>
                            <td> {item.accountId} </td>
                            <td> {item.createdDate} </td>

                            <td>
                                <div>
                                    <Link className="btn btn-info btn-sm" to={'/admin/comment/edit/' + item.id}><i className="fas fa-pencil-alt">
                                    </i> {t('button.edit')} </Link>
                                    <button type="button" onClick={() => this.setState({ selectedDeletionItemId: item.id })} className="btn btn-danger btn-sm" data-toggle="modal" data-target="#modal-delete">
                                        <i className="fas fa-trash" /> {t('button.delete')}
                                    </button>
                                </div>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
            <DeleteDialog {...this.state} {...this.props} />
        </div>
    }
}
export default withRouter(withTranslation()(LessonComment));