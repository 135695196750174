
import React, { Component } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';

import API from '../../API';
import { handleError } from '../shared/ResponseHandler';


class LessonInfo extends React.Component {

    render() {
        return (<div className="content-wrapper">
            <Breadcrumb />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-secondary">
                                <FormHeader />
                                <FormBody {...this.props} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        )
    }
}

const Breadcrumb = () => {
    const { t } = useTranslation();
    return <section className="content-header">
        <div className="container-fluid">
            <div className="row mb-2">
                <div className="col-sm-6">
                    <h1><Link to="/admin/lesson"><i className="fas fa-arrow-left fa-sm" /> </Link>  {t('admin:lesson.info.title')}</h1>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/admin">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/admin/lesson">{t('admin:meta.lesson.title')}</Link></li>
                        <li className="breadcrumb-item active">{t('admin:lesson.info.title')}</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
}

const FormHeader = () => {
    const { t } = useTranslation()
    return <div className="card-header">
        <h3 className="card-title">{t('admin:lesson.info.title')}</h3>
    </div>
}

class FormBody extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: 0,
            status: 'new',
            name: '',
            description: '',
            videoUrl: '',
            timeAmount: '',
            type: 'VIDEO',
            metaKeyword: '',
            metaDescription: '',
            imageUrl: "",
            sourceCode:''
            // imageFile: null
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        const { match } = this.props;
        var id = match.params.id;
        var resp = await API.authorizedJSONGET("/api/teacher/lesson/" + id);
        if (resp.ok) {
            var lesson = await resp.json();
            this.setState(lesson);
        } else {
            handleError(resp, this)
        }
    }


    render() {
        const { t } = this.props;

        return <div className="card-body">
            <div className="form-group">
                <label className="col-1">#</label> : {this.state.id}
            </div>
            <div className="form-group">
                <label className="col-1">{t('admin:lesson.type')}</label>: {this.state.type}
            </div>
            <div className="form-group">
                <label className="col-1">{t('admin:lesson.isPublic')}</label>: {this.state.isPublic==true?"public":"private"}
            </div>
            <div className="form-group">
                <label className="col-1">{t('admin:lesson.name')}</label>: {this.state.name}
            </div>
            <div className="form-group">
                <label className="col-1">{t('admin:lesson.imageUrl')}</label>: <img src={this.state.imageUrl} className="img-thumbnail" width="150" />
            </div>
            <div className="form-group">
                <label className="col-1">{t('admin:lesson.videoUrl')}</label>: {this.state.videoUrl}
            </div>
            <div className="form-group">
                <label className="col-1">{t('admin:lesson.timeAmount')}</label>: {this.state.timeAmount} m
            </div>
            <div className="form-group">
                <label className="col-12">{t('admin:lesson.metaKeyword')}</label><div className="col-12 border border-secondary rounded">{this.state.metaKeyword}</div>
            </div>
            <div className="form-group">
                <label className="col-12">{t('admin:lesson.metaDescription')}</label>
                <div className="col-12 border border-secondary rounded">{this.state.metaDescription}</div>
            </div>
            <div className="form-group">
                <label className="col-12">{t('admin:lesson.sourceCode')}</label>
                <div className="col-12 border border-secondary rounded">{this.state.sourceCode}</div>
            </div>
            <div className="form-group">
                <label className="col-12">{t('admin:lesson.description')}</label>
                <div className="col-12 border border-secondary rounded"><p dangerouslySetInnerHTML={{ __html: this.state.description }}></p></div>
            </div>
        </div>
    }
}
export default withRouter(withTranslation()(LessonInfo));