import firebase from 'firebase';
// var firebaseConfig = {
//   apiKey: "AIzaSyCO0HnxSVHfCwvYdiPs_HLZTg_xawLr0fY",
//   authDomain: "javamaster-test.firebaseapp.com",
//   databaseURL: "https://javamaster-test.firebaseio.com",
//   projectId: "javamaster-test",
//   storageBucket: "javamaster-test.appspot.com",
//   messagingSenderId: "396629979133",
//   appId: "1:396629979133:web:61c18ea57e0b71da117394"
// };
var firebaseConfig = {
  apiKey: "AIzaSyAm7phMXBU0qo0BBW4bpoCyypUdkJmfZDs",
  authDomain: "javamaster-1429a.firebaseapp.com",
  databaseURL: "https://javamaster-1429a.firebaseio.com",
  projectId: "javamaster-1429a",
  storageBucket: "javamaster-1429a.appspot.com",
  messagingSenderId: "544854454700",
  appId: "1:544854454700:web:0e238b1263beefbd78ca09",
  measurementId: "G-DL622L95BK"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
