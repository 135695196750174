import { Editor } from '@tinymce/tinymce-react';
import React, { Component, useState } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import AlertBlockUI from '../shared/AlertBlockUI';
import API from '../../API';
import { handleError } from '../shared/ResponseHandler';
import AsyncSelect from 'react-select/async';
import getSlug from 'speakingurl';
import { tinyConfig } from '../shared/TinyConfig';
import AuthVerification from '../../AuthVerification';

class LearnPathAdd extends React.Component {
    render() {
        return (
            <div className="card card-secondary">
                <FormHeader />
                <FormBody {...this.props} />
            </div>
        );
    }
}
const FormHeader = () => {
    const { t } = useTranslation()
    return <div className="card-header">
        <h3 className="card-title">{t('admin:learnPath.add.title')}</h3>
    </div>
}

class FormBody extends Component {
    constructor(props) {
        super(props)
        this.state = {
            metaKeyword: '',
            metaDescription: '',
            description: '',
            name: '',
            price: '',
            imageFile: null,
            pathStatus:'',
            //search
            start: 0,
            length: 10,
            keyword: ""
        }
    }

    setCourseMetaKeyword = (event) => this.setState({ metaKeyword: event.target.value })
    setCourseMetaDescription = (event) => this.setState({ metaDescription: event.target.value })
    setCourseDescription = (event) => this.setState({ description: event })
    setPathName = (event) => this.setState({ name: event.target.value })
    setCoursePrice = (event) => this.setState({ price: event.target.value })
    setPathStatus = (event) => this.setState({ pathStatus: event.target.value })
    setCourseImageFile = (event) => this.setState({ imageFile: event.target.files[0] })

    loadCategoryData = async () => {
        var resp = await API.authorizedJSONPost("/api/admin/category/search", this.state);

        if (resp.status == 200) {
            var json = await resp.json();
            this.setState({ responseCategoryData: json })
        } else {
            handleError(resp, this)
        }
    }

    loadCategoryOptions = async (inputValue) => {
        this.setState({ keyword: inputValue })
        await this.loadCategoryData();
        const options = [];
        this.state.responseCategoryData.data.forEach(item => {
            options.push({ value: item, label: item.categoryName })
        })
        return options
    };


    submit = async () => {
        //upload firebase storage
        const folderImage = 'path/' + AuthVerification.currentUser().uid + "/"
        const name = getSlug(this.state.courseName) + "-" + new Date().getTime()
        const imageUrl = await API.firebaseFileUpload(this.state.imageFile, folderImage + name)
        this.setState({ imageUrl: imageUrl }, this.addCourseToBackend)
    }

    addCourseToBackend = async () => {
        var resp = await API.authorizedJSONPost("/api/teacher/learn-path/add", this.state);
        if (resp.ok) {
            this.setState({ status: "ok" })
            this.props.loadData()
        } else {
            handleError(resp, this)
        }
    }

    render() {
        const { t } = this.props;

        return <form role="form" id="form">
            <div className="card-body">
                <div className="form-group">
                    <label >{t('admin:learnPath.name')}</label>
                    <input type="text" className="form-control" onChange={this.setPathName}
                        value={this.state.name} placeholder={t('admin:learnPath.name')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:learnPath.price')}</label>
                    <input type="number" className="form-control" onChange={this.setCoursePrice}
                        value={this.state.price} placeholder={t('admin:learnPath.price')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:learnPath.imageUrl')}</label>
                    <input type="file" name="imageFile" className="form-control-file" onChange={this.setCourseImageFile}
                        placeholder={t('admin:learnPath.imageUrl')} required />
                </div>
                <div className="form-group">
                    <label className="col-form-label">{t('admin:learnPath.category')}</label>
                    <div className="col-md-12">
                        <AsyncSelect
                            cacheOptions
                            loadOptions={this.loadCategoryOptions}
                            defaultOptions
                            isClearable={true}
                            isSearchable={true}
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    this.setState({ courseCategoryDTO: { id: selectedOption.value.id } })
                                } else {
                                    this.setState({ courseCategoryDTO: null })
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label >{'Trạng Thái'}</label>
                    <select className="form-control" onChange={this.setPathStatus} 
                        value={this.state.pathStatus} >
                        <option value="DRAFT">DRAFT</option>
                        <option value="PUBLISH">PUBLISH</option>
                    </select>
                </div>
                
                <div className="form-group">
                    <label >{t('admin:learnPath.metaKeyword')}</label>
                    <input type="text" className="form-control" onChange={this.setCourseMetaKeyword}
                        value={this.state.metaKeyword} placeholder={t('admin:learnPath.metaKeyword')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:learnPath.metaDescription')}</label>
                    <textarea type="text" className="form-control" onChange={this.setCourseMetaDescription}
                        value={this.state.metaDescription} placeholder={t('admin:learnPath.metaDescription')} required ></textarea>
                </div>
                <div className="form-group">
                    <label >{t('admin:learnPath.description')}</label>
                    <Editor apiKey="rhk66ixiw1z5jxhvf5o22ouhtq5l3vh1yj8jq3xzgycsd1hd"
                        initialValue={this.state.description}
                        init={tinyConfig}
                        onEditorChange={this.setCourseDescription}
                    />
                </div>
            </div>
            <div className="card-footer">
                <button type="button" onClick={this.submit} className="btn btn-info">{t('button.save')}</button>
            </div>
            <AlertBlockUI {...this.state} setState={(status) => this.setState(status)} />
        </form>
    }
}
export default withRouter(withTranslation()(LearnPathAdd));