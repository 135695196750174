import React, { Component, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import AsyncSelect from 'react-select/async';

import API from '../../API';
import AlertBlockUI from '../shared/AlertBlockUI';
import { handleError } from '../shared/ResponseHandler';

class UserEdit extends React.Component {

    render() {
        return (<div className="content-wrapper">
            <Breadcrumb />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-secondary">
                                <FormHeader />
                                <FormBody {...this.props} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        )
    }
}

const Breadcrumb = () => {
    const { t } = useTranslation();
    return <section className="content-header">
        <div className="container-fluid">
            <div className="row mb-2">
                <div className="col-sm-6">
                    <h1><Link to="/admin/account"><i className="fas fa-arrow-left fa-sm" /> </Link>  {t('admin:account.edit.title')}</h1>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/admin">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/admin/account">{t('admin:meta.account.title')}</Link></li>
                        <li className="breadcrumb-item active">{t('admin:account.edit.title')}</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
}

const FormHeader = () => {
    const { t } = useTranslation()
    return <div className="card-header">
        <h3 className="card-title">{t('admin:account.edit.title')}</h3>
    </div>
}

class FormBody extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            name: '',
            phoneNumber: '',
            roles: []
        }
    }

    componentDidMount() {
        this.loadData();
    }


    loadData = async () => {
        const { match } = this.props;
        var id = match.params.id;
        var resp = await API.authorizedJSONGET("/api/admin/account/" + id);
        if (resp.ok) {
            var user = await resp.json();
            this.setState(user);
        } else {
            handleError(resp, this)
        }
    }


    setEmail = (event) => this.setState({ email: event.target.value })
    setName = (event) => this.setState({ name: event.target.value })
    setPhoneNumber = (event) => this.setState({ phoneNumber: event.target.value })
    setRoles = (event) => {
        var options = event.target.options;
        var values = [];
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                values.push(options[i].value);
            }
        }
        this.setState({ roles: values });
    }
    submit = async () => {
        var resp = await API.authorizedJSONPut("/api/admin/account/update", this.state);
        if (resp.ok) {
            this.setState({ status: "ok" })
        } else {
            handleError(resp, this)
        }
    }

    render() {
        const { t } = this.props;
        return <form >
            <div className="card-body">
                <div className="form-group">
                    <label>#</label>
                    <input type="text" className="form-control"
                        value={this.state.id} disabled />
                </div>
                <div className="form-group">
                    <label >{t('admin:account.name')}</label>
                    <input type="text" className="form-control" onChange={this.setName}
                        value={this.state.name} placeholder={t('admin:account.name')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:account.email')}</label>
                    <input type="email" className="form-control" onChange={this.setEmail}
                        value={this.state.email} placeholder={t('admin:account.email')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:account.phoneNumber')}</label>
                    <input type="phone" className="form-control" onChange={this.setPhoneNumber}
                        value={this.state.phoneNumber} placeholder={t('admin:account.phoneNumber') + '+84123456789'} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:account.roles')}</label>
                    <select className="form-control" onChange={this.setRoles} multiple
                        value={this.state.roles} >
                        <option value="ROLE_MEMBER">ROLE_MEMBER</option>
                        <option value="ROLE_TEACHER">ROLE_TEACHER</option>
                        <option value="ROLE_ADMIN">ROLE_ADMIN</option>
                    </select>
                </div>
            </div>
            <div className="card-footer">
                <button type="button" onClick={this.submit} className="btn btn-info">{t('button.save')}</button>
            </div>
            <AlertBlockUI {...this.state} setState={(status) => this.setState(status)} />
        </form>
    }
}

export default withRouter(withTranslation()(UserEdit));