
import React, { Component, Fragment } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';

import API from '../../API';
import { VNDFormatter } from '../../i18n';
import { handleError } from '../shared/ResponseHandler';


class CourseInfo extends React.Component {

    render() {
        return (<div className="content-wrapper">
            <Breadcrumb />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-secondary">
                                <FormHeader />
                                <FormBody {...this.props} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        )
    }
}

const Breadcrumb = () => {
    const { t } = useTranslation();
    return <section className="content-header">
        <div className="container-fluid">
            <div className="row mb-2">
                <div className="col-sm-6">
                    <h1><Link to="/admin/course"><i className="fas fa-arrow-left fa-sm" /> </Link>  {t('admin:course.info.title')}</h1>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/admin">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/admin/course">{t('admin:meta.course.title')}</Link></li>
                        <li className="breadcrumb-item active">{t('admin:course.info.title')}</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
}

const FormHeader = () => {
    const { t } = useTranslation()
    return <div className="card-header">
        <h3 className="card-title ">{t('admin:course.info.title')}
        </h3>
    </div>
}

class FormBody extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: 0,
            status: 'new',
            courseName: '',
            description: '',
            price: 0,
            metaKeyword: '',
            metaDescription: '',
            imageUrl: "",
            courseCategoryDTO: {},
            levelDTO: {},
            supportFeatureDTOs: [],
            teacherDTOs:[]
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        const { match } = this.props;
        var id = match.params.id;
        var resp = await API.authorizedJSONGET("/api/teacher/course/" + id);
        if (resp.ok) {
            var course = await resp.json();
            this.setState(course);
        } else {
            handleError(resp, this)
        }
    }


    render() {
        const { t } = this.props;

        return <div className="card-body">
            <div className="form-group">
                <label className="col-1">#</label> : {this.state.id}
            </div>
            <div className="form-group">
                <label className="col-1">{t('admin:course.name')}</label>: {this.state.courseName}
            </div>
            <div className="form-group">
                <label className="col-1">{t('admin:course.status')}</label>: {this.state.courseStatus}
            </div>
            <div className="form-group">
                <label className="col-1">{t('admin:course.price')}</label>: {VNDFormatter.format(this.state.price)}
            </div>
            <div className="form-group">
                <label className="col-1">{t('admin:course.imageUrl')}</label>: <img src={this.state.imageUrl} className="img-thumbnail" width="150" />
            </div>
            <div className="form-group">
                <label className="col-1">{t('admin:course.level')}</label>: {this.state.levelDTO.levelName}
            </div>
            <div className="form-group">
                <label className="col-1">{t('admin:course.category')}</label>: {this.state.courseCategoryDTO.categoryName}
            </div>
            <div className="form-group">
                <label className="col-1">{t('admin:course.lessonQuantity')}</label>: {this.state.lessonQuantity}
            </div>
            <div className="form-group">
                <label className="col-12">{t('admin:course.metaKeyword')}</label><div className="col-12 border border-secondary rounded">{this.state.metaKeyword}</div>
            </div>
            <div className="form-group">
                <label className="col-12">{t('admin:course.metaDescription')}</label>
                <div className="col-12 border border-secondary rounded">{this.state.metaDescription}</div>
            </div>
            <div className="form-group">
                <label className="col-12">{t('admin:course.supportfeature')}</label>
                <div className="col-12 border border-secondary rounded">{this.state.supportFeatureDTOs.map((item, index) => { return <p key={index}> <span dangerouslySetInnerHTML={{ __html: item.icon }}></span> {item.feature}</p> })}</div>
            </div>
            <div className="form-group">
                <label className="col-12">{t('admin:account.teacher')}</label>
                <div className="col-12 border border-secondary rounded">{this.state.teacherDTOs.map((item, index) => { return <p key={index}>  {item.name}</p> })} {console.log(this.state.teacherDTOs)}</div>
            </div>
            <div className="form-group">
                <label className="col-12">{t('admin:course.description')}</label>
                <div className="col-12 border border-secondary rounded"><p dangerouslySetInnerHTML={{ __html: this.state.description }}></p></div>
            </div>
        </div>
    }
}
export default withRouter(withTranslation()(CourseInfo));