import './FirebaseConfig';
import './index.css';
import './i18n';

import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { usePromiseTracker } from 'react-promise-tracker';

import App from './App';
import * as serviceWorker from './serviceWorker';

const LoadingIndicator = () => {
  const { promiseInProgress } = usePromiseTracker();

  return (
    promiseInProgress &&
    <div className="loading-app" >
      <div className="circle"><div className="overlay d-flex justify-content-center align-items-center">
        <i className="fas fa-2x fa-spinner fa-pulse"></i>
      </div></div>
    </div>
  );
}

ReactDOM.render(
  <Suspense fallback={null}>
    <App />
    <LoadingIndicator />
  </Suspense>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
