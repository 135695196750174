import { Editor } from '@tinymce/tinymce-react';
import React, { Component } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import AlertBlockUI from '../shared/AlertBlockUI';
import API from '../../API';
import { handleError } from '../shared/ResponseHandler';
import { tinyConfig } from '../shared/TinyConfig';
import getSlug from 'speakingurl';
import AuthVerification from '../../AuthVerification';

class LessonEdit extends React.Component {

    render() {
        return (<div className="content-wrapper">
            <Breadcrumb />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-secondary">
                                <FormHeader />
                                <FormBody {...this.props} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        )
    }
}

const Breadcrumb = () => {
    const { t } = useTranslation();
    return <section className="content-header">
        <div className="container-fluid">
            <div className="row mb-2">
                <div className="col-sm-6">
                    <h1><Link to="/admin/lesson"><i className="fas fa-arrow-left fa-sm" /> </Link>  {t('admin:lesson.edit.title')}</h1>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/admin">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/admin/lesson">{t('admin:meta.lesson.title')}</Link></li>
                        <li className="breadcrumb-item active">{t('admin:lesson.edit.title')}</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
}

const FormHeader = () => {
    const { t } = useTranslation()
    return <div className="card-header">
        <h3 className="card-title">{t('admin:lesson.edit.title')}</h3>
    </div>
}

class FormBody extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: 0,
            status: 'new',
            name: '',
            description: '',
            videoUrl: '',
            timeAmount: '',
            type: 'VIDEO',
            metaKeyword: '',
            metaDescription: '',
            isPublic: true,
            sourceCode: ''
        }
        this.editor = React.createRef();
        this.user = AuthVerification.currentUser()
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        const { match } = this.props;
        var id = match.params.id;
        var resp = await API.authorizedJSONGET("/api/teacher/lesson/" + id);
        if (resp.ok) {
            var lesson = await resp.json();
            this.setState(lesson);
            this.editor.current.editor.setContent(lesson.description);
        } else {
            handleError(resp, this)
        }
    }

    setLessonType = (event) => this.setState({ type: event.target.value })
    setLessonName = (event) => this.setState({ name: event.target.value })
    setLessonVideoUrl = (event) => this.setState({ videoUrl: event.target.value })
    setLessonTimeAmount = (event) => this.setState({ timeAmount: event.target.value })
    setLessonMetaKeyWord = (event) => this.setState({ metaKeyword: event.target.value })
    setLessonMetaDescription = (event) => this.setState({ metaDescription: event.target.value })
    setLessonDescription = (event) => this.setState({ description: event })
    setLessonImageFile = (event) => this.setState({ imageFile: event.target.files[0] })
    setLessonIsPublic = (event) => this.setState({ isPublic: event.target.value })
    setSourceCode = (event) => this.setState({ sourceCode: event.target.value })

    getImageUrl = (item) => {
        if (item.type === 'YOUTUBE') {
            return "https://img.youtube.com/vi/" + item.videoUrl + "/hqdefault.jpg"
        } else if (item.type === 'VIDEO') {
            return "https://videodelivery.net/" + item.videoUrl + "/thumbnails/thumbnail.jpg?time=7s&height=480"
        }
        return item.imageUrl
    }

    submit = async () => {
        if (this.state.imageFile) {
            const oldImageUrl = this.state.imageUrl
            const folderImage = 'lesson/' + this.user.uid + "/"
            const name = getSlug(this.state.name) + "-" + new Date().getTime()
            const imageUrl = await API.firebaseFileUpload(this.state.imageFile, folderImage + name)
            this.setState({ imageUrl: imageUrl, oldImageUrl: oldImageUrl }, this.addLessonToBackend)
        } else {
            this.setState({ imageUrl: this.getImageUrl(this.state) }, this.addLessonToBackend)
        }
    }

    addLessonToBackend = async () => {
        var resp = await API.authorizedJSONPut("/api/teacher/lesson/update", this.state);
        if (resp.ok) {
            if (this.state.oldImageUrl) {
                const url = this.state.oldImageUrl
                await API.firebaseFileDelete(decodeURIComponent(url.substring(url.indexOf("lesson"), url.indexOf("?"))))
            }
            this.setState({ status: "ok" })
        } else {
            handleError(resp, this)
        }
    }

    render() {
        const { t } = this.props;

        return <form id="form">
            <div className="card-body">
                <div className="form-group">
                    <label>#</label>
                    <input type="text" className="form-control" disabled
                        value={this.state.id} />
                </div>
                <div className="form-group">
                    <label >{t('admin:lesson.type')}</label>
                    <select className="form-control" onChange={this.setLessonType}
                        value={this.state.type} >
                        <option value="VIDEO">VIDEO</option>
                        <option value="TEXT">TEXT</option>
                        <option value="YOUTUBE">YOUTUBE</option>
                    </select>
                </div>
                <div className="form-group">
                    <label >{t('admin:lesson.isPublic')}</label>
                    <select className="form-control" onChange={this.setLessonIsPublic}
                        value={this.state.isPublic} >
                        <option value="true">Public</option>
                        <option value="false">Private</option>

                    </select>
                </div>
                <div className="form-group">
                    <label >{t('admin:lesson.name')}</label>
                    <input type="text" className="form-control" onChange={this.setLessonName}
                        value={this.state.name} placeholder={t('admin:lesson.name')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:lesson.imageUrl')}</label>
                    <input type="file" name="imageFile" className="form-control-file" onChange={this.setLessonImageFile}
                        required accept="image/*" />
                </div>
                <div className="form-group">
                    <label >{t('admin:lesson.videoUrl')}</label>
                    <input type="text" className="form-control" onChange={this.setLessonVideoUrl}
                        value={this.state.videoUrl} placeholder={t('admin:lesson.videoUrl')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:lesson.timeAmount')}</label>
                    <input type="text" className="form-control" onChange={this.setLessonTimeAmount}
                        value={this.state.timeAmount} placeholder={t('admin:lesson.timeAmount')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:lesson.metaKeyword')}</label>
                    <input type="text" className="form-control" onChange={this.setLessonMetaKeyWord}
                        value={this.state.metaKeyword} placeholder={t('admin:lesson.metaKeyword')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:lesson.metaDescription')}</label>
                    <textarea className="form-control" onChange={this.setLessonMetaDescription}
                        value={this.state.metaDescription} placeholder={t('admin:lesson.metaDescription')} required ></textarea>
                </div>
                <div className="form-group">
                    <label >{t('admin:lesson.sourceCode')}</label>
                    <input type="text" className="form-control" onChange={this.setSourceCode}
                        value={this.state.sourceCode} placeholder={t('admin:lesson.sourceCode')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:lesson.description')}</label>
                    <Editor apiKey="rhk66ixiw1z5jxhvf5o22ouhtq5l3vh1yj8jq3xzgycsd1hd"
                        initialValue={this.state.description}
                        init={tinyConfig}
                        ref={this.editor}
                        onEditorChange={this.setLessonDescription}
                    />
                </div>
            </div>
            <div className="card-footer">
                <button type="button" onClick={this.submit} className="btn btn-info">{t('button.save')}</button>
            </div>
            <AlertBlockUI {...this.state} setState={(status) => this.setState(status)} />
        </form>
    }
}
export default withRouter(withTranslation()(LessonEdit));