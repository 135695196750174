import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class DeleteDialog extends Component {
    render() {
        let { t } = this.props;
        return <div className="modal fade" id="modal-delete">
            <div className="modal-dialog modal-sm">
                <div className="modal-content ">
                    <div className="modal-body">
                        <p>{t('admin:message.delete.confirm')} #<b>{this.props.selectedDeletionItemId}</b></p>
                    </div>
                    <div className="modal-footer justify-content-between">
                        <button type="button" className="btn btn-default btn-sm" data-dismiss="modal">{t('admin:button.cancel')}</button>
                        <button type="button" className="btn btn-danger btn-sm" data-dismiss="modal" onClick={() => this.props.deleteItem([this.props.selectedDeletionItemId], [this.props.selectedImageUrl])}><i className="fas fa-check" /> {t('admin:button.delete')}</button>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default withTranslation()(DeleteDialog)