
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import Index from './admin/Index';
import { AppRoutes } from './AppRoutes';
import AuthVerification from './AuthVerification';
import PageMeta from './PageMeta';

function App() {
  const { t } = useTranslation();

  return (
    <BrowserRouter>
      <Switch>
        <Route path="/admin" render={() => AuthVerification.isAuthenticated() && AuthVerification.currentUser() && AuthVerification.currentUser().roles.indexOf("ROLE_ADMIN") !== -1 ?
          <Index /> : <Redirect to="/login" />} />
        {AppRoutes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            exact={route.exact}
            render={() => {
              return (
                <Fragment>
                  <PageMeta title={t(route.title)} description={t(route.description)} />
                  <route.main />
                </Fragment>)
            }}
          />
        ))}
      </Switch>
    </BrowserRouter>
  )
}

// function PrivateRoute({ children, ...rest }) {
//   return (
//     <Route
//       {...rest}
//       render={() => {
//         return AuthVerification.isAuthenticated() && AuthVerification.currentUser().roles.indexOf("ROLE_ADMIN") != -1 ?
//           children : <Redirect to="/login" />
//       }}
//     />
//   )
// }

export default App;
