import { Editor } from '@tinymce/tinymce-react';
import React, { Component, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';

import API from '../../API';
import AlertBlockUI from '../shared/AlertBlockUI';
import { handleError } from '../shared/ResponseHandler';
import { tinyConfig } from '../shared/TinyConfig';

class UpdateTeacher extends React.Component {

    render() {
        return (<div className="content-wrapper">
            <Breadcrumb />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-secondary">
                                <FormHeader />
                                <FormBody {...this.props} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        )
    }
}

const Breadcrumb = () => {
    const { t } = useTranslation();
    return <section className="content-header">
        <div className="container-fluid">
            <div className="row mb-2">
                <div className="col-sm-6">
                    <h1><Link to="/admin/account"><i className="fas fa-arrow-left fa-sm" /> </Link>  {t('admin:account.edit.teacher')}</h1>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/admin">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/admin/account">{t('admin:meta.account.title')}</Link></li>
                        <li className="breadcrumb-item active">{t('admin:account.edit.teacher')}</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
}

const FormHeader = () => {
    const { t } = useTranslation()
    return <div className="card-header">
        <h3 className="card-title">{t('admin:account.edit.teacher')}</h3>
    </div>
}

class FormBody extends Component {
    constructor(props) {
        super(props)
        this.state = {
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        const { match } = this.props;
        var id = match.params.id;
        this.setState({ id })
        var resp = await API.authorizedJSONGET("/api/teacher/" + id);
        if (resp.ok) {
            const teacher = await resp.json();
            this.setState(teacher);
        }
    }

    setDescription = (event) => {
        this.setState({ description: event })
    }

    setExperience = (event) => {
        this.setState({ experience: event.target.value })
    }

    submit = async () => {
        var resp = await API.authorizedJSONPut("/api/teacher/update", this.state);
        if (resp.ok) {
            this.setState({ status: "ok" })
        } else {
            handleError(resp, this)
        }
    }

    render() {
        const { t } = this.props;
        return <form >
            <div className="card-body">
                <div className="form-group">
                    <label>#</label>
                    <input type="text" className="form-control" value={this.state.id} disabled />
                </div>
                <div className="form-group">
                    <label >{t('admin:account.experience')}</label>
                    <input type="text" className="form-control" onChange={this.setExperience}
                        value={this.state.experience} placeholder={t('admin:account.experience')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:account.description')}</label>
                    <Editor apiKey="rhk66ixiw1z5jxhvf5o22ouhtq5l3vh1yj8jq3xzgycsd1hd"
                        initialValue={this.state.description}
                        init={tinyConfig}
                        onEditorChange={this.setDescription}
                    />
                </div>
            </div>
            <div className="card-footer">
                <button type="button" onClick={() => this.submit()} className="btn btn-info">{t('button.save')}</button>
            </div>
            <AlertBlockUI {...this.state} setState={(status) => this.setState(status)} />
        </form>
    }
}

export default withRouter(withTranslation()(UpdateTeacher));