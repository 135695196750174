import React, { Component } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';

import API from '../../API';
import AlertBlockUI from '../shared/AlertBlockUI';
import { handleError } from '../shared/ResponseHandler';

class SlideEdit extends React.Component {

    render() {
        return (<div className="content-wrapper">
            <Breadcrumb />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-secondary">
                                <FormHeader />
                                <FormBody {...this.props} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        )
    }
}

const Breadcrumb = () => {
    const { t } = useTranslation();
    return <section className="content-header">
        <div className="container-fluid">
            <div className="row mb-2">
                <div className="col-sm-6">
                    <h1><Link to="/admin/slide"><i className="fas fa-arrow-left fa-sm" /> </Link>  {t('admin:slide.edit.title')}</h1>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/admin">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/admin/slide">{t('admin:meta.slide.title')}</Link></li>
                        <li className="breadcrumb-item active">{t('admin:slide.edit.title')}</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
}

const FormHeader = () => {
    const { t } = useTranslation()
    return <div className="card-header">
        <h3 className="card-title">{t('admin:slide.edit.title')}</h3>
    </div>
}

class FormBody extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: 0,
            linkTo: "",
            imageUrl: "",
            imageFile: null
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        const { match } = this.props;
        var id = match.params.id;
        var resp = await API.authorizedJSONGET("/api/admin/slide/" + id);
        if (resp.ok) {
            var lesson = await resp.json();
            this.setState(lesson);
        } else {
            handleError(resp, this)
        }
    }

    setSlideURL = (event) => this.setState({ linkTo: event.target.value })

    setSlideImageFile = (event) => this.setState({ imageFile: event.target.files[0] })

    submit = async () => {
        if (this.state.imageFile) {
            const oldImageUrl = this.state.imageUrl
            const folderImage = 'slider/'
            const name = new Date().getTime()
            const imageUrl = await API.firebaseFileUpload(this.state.imageFile, folderImage + name)
            this.setState({ imageUrl: imageUrl, oldImageUrl: oldImageUrl }, this.addSlideToBackend)
        } else {
            this.addSlideToBackend()
        }
    }

    addSlideToBackend = async () => {
        var resp = await API.authorizedJSONPost("/api/admin/slide/update", this.state);
        if (resp.ok) {
            if (this.state.oldImageUrl) {
                const url = this.state.oldImageUrl
                await API.firebaseFileDelete(decodeURIComponent(url.substring(url.indexOf("slider"), url.indexOf("?"))))
            }
            this.setState({ status: "ok" })
        } else {
            handleError(resp, this)
        }
    }

    render() {
        const { t } = this.props;

        return <form id="form">
            <div className="card-body">
                <div className="form-group">
                    <label>#</label>
                    <input type="text" className="form-control" disabled
                        value={this.state.id} />
                </div>

                <div className="form-group">
                    <label >{t('admin:slide.url')}</label>
                    <input type="text" className="form-control" onChange={this.setSlideURL}
                        value={this.state.linkTo} placeholder={t('admin:slide.url')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:slide.url')}</label>
                    <input type="file" name="imageFile" className="form-control-file" onChange={this.setSlideImageFile}
                        required accept="image/*" />
                </div>

            </div>
            <div className="card-footer">
                <button type="button" onClick={this.submit} className="btn btn-info">{t('button.save')}</button>
            </div>
            <AlertBlockUI {...this.state} setState={(status) => this.setState(status)} />
        </form>
    }
}
export default withRouter(withTranslation()(SlideEdit));