import React, { Component, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import API from '../../API';
import AlertBlockUI from '../shared/AlertBlockUI';
import { handleError } from '../shared/ResponseHandler';

class CourseSectionAdd extends React.Component {

    render() {
        return (
            <div className="card card-secondary">
                <FormHeader />
                <FormBody {...this.props} />
            </div>
        );
    }
}
const FormHeader = () => {
    const { t } = useTranslation()
    return <div className="card-header">
        <h3 className="card-title">{t('admin:course.courseSection.add.title')}</h3>
    </div>
}

class FormBody extends Component {
    constructor(props) {
        super(props)
        const { match } = this.props;

        var courseId = match.params.id;
        this.state = {
            courseId: courseId,
            name: '',
            sortOrder: 1,
        }
    }

    setCourseSectionName = (event) => this.setState({ name: event.target.value })
    setCourseSortOrder = (event) => this.setState({ sortOrder: event.target.value })

    submit = async () => {
        var resp = await API.authorizedJSONPost("/api/teacher/course-section/add", this.state);
        if (resp.ok) {
            this.setState({ status: "ok" })
            this.props.loadData()
        } else {
            handleError(resp, this)
        }
    }

    render() {
        const { t } = this.props;

        return <form role="form" id="form">
            <div className="card-body">
                <div className="form-group">
                    <label >{t('admin:course.courseSection.courseSectionName')}</label>
                    <input type="text" className="form-control" onChange={this.setCourseSectionName}
                        value={this.state.name} placeholder={t('admin:course.courseSection.courseSectionName')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:course.courseSection.sortOrder')}</label>
                    <input type="text" className="form-control" onChange={this.setCourseSortOrder}
                        value={this.state.sortOrder} placeholder={t('admin:course.courseSection.sortOrder')} required />
                </div>
            </div>
            <div className="card-footer">
                <button type="button" onClick={this.submit} className="btn btn-info">{t('button.save')}</button>
            </div>
            <AlertBlockUI {...this.state} setState={(status) => this.setState(status)} />
        </form>
    }
}
export default withRouter(withTranslation()(CourseSectionAdd));