import React, { Component, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import AsyncSelect from 'react-select/async';

import API from '../../API';
import AlertBlockUI from '../shared/AlertBlockUI';
import { handleError } from '../shared/ResponseHandler';

class CourseSectionLessonAdd extends React.Component {

    render() {
        return (
            <div className="card card-secondary">
                <FormHeader />
                <FormBody {...this.props} />
            </div>
        );
    }
}
const FormHeader = () => {
    const { t } = useTranslation()
    return <div className="card-header">
        <h3 className="card-title">{t('admin:course.courseSectionLesson.add.title')}</h3>
    </div>
}

class FormBody extends Component {
    constructor(props) {
        super(props)
        const { match } = this.props;
        var courseId = match.params.id;

        this.state = {
            sortOrder: 1,
            lessonDTO: null,
            courseSectionId: null,
            //search
            start: 0,
            length: 10,
            keyword: "",
            courseId: courseId
        }
    }

    setCourseSectionName = (event) => this.setState({ name: event.target.value })
    setCourseSortOrder = (event) => this.setState({ sortOrder: event.target.value })

    loadLessonData = async () => {
        var resp = await API.authorizedJSONPost("/api/teacher/lesson/search", this.state);

        if (resp.status == 200) {
            var json = await resp.json();
            this.setState({ responseLessonData: json })
        } else {
            handleError(resp, this)
        }
    }

    loadLesson = async (inputValue) => {
        this.setState({ keyword: inputValue })
        await this.loadLessonData();
        const options = [];
        this.state.responseLessonData.data.forEach(item => {
            options.push({ value: item, label: item.name })
        })
        return options
    };

    loadCourseSectionData = async () => {
        var resp = await API.authorizedJSONPost("/api/teacher/course-section/search", this.state);

        if (resp.status == 200) {
            var json = await resp.json();
            this.setState({ responseCourseSectionData: json })
        } else {
            handleError(resp, this)
        }
    }

    loadCourseSection = async (inputValue) => {
        this.setState({ keyword: inputValue })
        await this.loadCourseSectionData();
        const options = [];
        this.state.responseCourseSectionData.data.forEach(item => {
            options.push({ value: item, label: item.name })
        })
        return options
    };

    submit = async () => {
        var resp = await API.authorizedJSONPost("/api/teacher/course-section-lesson/add", this.state);
        if (resp.ok) {
            this.setState({ status: "ok" })
            this.props.loadData()
        } else {
            handleError(resp, this)
        }
    }

    render() {
        const { t } = this.props;

        return <form role="form" id="form">
            <div className="card-body">
                <div className="form-group">
                    <label className="col-form-label">{t('admin:course.courseSection')}</label>
                    <div className="col-md-12">
                        <AsyncSelect
                            cacheOptions
                            loadOptions={this.loadCourseSection}
                            defaultOptions
                            isClearable={true}
                            isSearchable={true}
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    this.setState({ courseSectionId: selectedOption.value.id })

                                } else {
                                    this.setState({ courseSectionId: null })
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-form-label">{t('admin:course.courseSectionLesson.lesson')}</label>
                    <div className="col-md-12">
                        <AsyncSelect
                            cacheOptions
                            loadOptions={this.loadLesson}
                            defaultOptions
                            isClearable={true}
                            isSearchable={true}
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    this.setState({ lessonDTO: selectedOption.value })

                                } else {
                                    this.setState({ lessonDTO: null })
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label >{t('admin:course.courseSection.sortOrder')}</label>
                    <input type="text" className="form-control" onChange={this.setCourseSortOrder}
                        value={this.state.sortOrder} placeholder={t('admin:course.courseSection.sortOrder')} required />
                </div>
            </div>
            <div className="card-footer">
                <button type="button" onClick={this.submit} className="btn btn-info">{t('button.save')}</button>
            </div>
            <AlertBlockUI {...this.state} setState={(status) => this.setState(status)} />
        </form>
    }
}
export default withRouter(withTranslation()(CourseSectionLessonAdd));