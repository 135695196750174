import { Editor } from '@tinymce/tinymce-react';
import React, { Component } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import getSlug from 'speakingurl';

import API from '../../API';
import AuthVerification from '../../AuthVerification';
import AlertBlockUI from '../shared/AlertBlockUI';
import { handleError } from '../shared/ResponseHandler';
import { tinyConfig } from '../shared/TinyConfig';

class LessonAdd extends React.Component {
    render() {
        return (
            <div className="card card-secondary">
                <FormHeader />
                <FormBody {...this.props} />
            </div>
        );
    }
}
const FormHeader = () => {
    const { t } = useTranslation()
    return <div className="card-header">
        <h3 className="card-title">{t('admin:lesson.add.title')}</h3>
    </div>
}

class FormBody extends Component {
    constructor(props) {
        super(props)
        this.state = {
            status: 'new',
            name: '',
            description: '',
            videoUrl: '',
            timeAmount: '',
            type: 'VIDEO',
            metaKeyword: '',
            metaDescription: '',
            imageFile: null,
            isPublic: true,
            sourceCode: ''
        }
    }

    setLessonType = (event) => this.setState({ type: event.target.value })
    setLessonName = (event) => this.setState({ name: event.target.value })
    setLessonVideoUrl = (event) => this.setState({ videoUrl: event.target.value })
    setLessonTimeAmount = (event) => this.setState({ timeAmount: event.target.value })
    setLessonMetaKeyWord = (event) => this.setState({ metaKeyword: event.target.value })
    setLessonMetaDescription = (event) => this.setState({ metaDescription: event.target.value })
    setLessonDescription = (event) => this.setState({ description: event })
    setLessonImageFile = (event) => this.setState({ imageFile: event.target.files[0] })
    setLessonIsPublic = (event) => this.setState({ isPublic: event.target.value })
    setSourceCode = (event) => this.setState({ sourceCode: event.target.value })

    getImageUrl = (item) => {
        if (item.type === 'YOUTUBE') {
            return "https://img.youtube.com/vi/" + item.videoUrl + "/hqdefault.jpg"
        } else if (item.type === 'VIDEO') {
            return "https://videodelivery.net/" + item.videoUrl + "/thumbnails/thumbnail.jpg?time=7s&height=480"
        }
        return item.imageUrl
    }

    submit = async () => {
        if (this.state.imageFile) {
            //upload firebase storage
            const folderImage = 'lesson/' + AuthVerification.currentUser().uid + "/"
            const name = getSlug(this.state.name) + "-" + new Date().getTime()
            const imageUrl = await API.firebaseFileUpload(this.state.imageFile, folderImage + name)
            this.setState({ imageUrl: imageUrl }, this.addLessonToBackend)
        } else {
            this.setState({ imageUrl: this.getImageUrl(this.state) }, this.addLessonToBackend)
        }
    }

    addLessonToBackend = async () => {
        var resp = await API.authorizedJSONPost("/api/teacher/lesson/add", this.state);
        if (resp.ok) {
            this.setState({ status: "ok" })
            this.props.loadData()
        } else {
            handleError(resp, this)
        }
    }

    render() {
        const { t } = this.props;

        return <form id="form">
            <div className="card-body">
                <div className="form-group">
                    <label >{t('admin:lesson.type')}</label>
                    <select className="form-control" onChange={this.setLessonType}
                        value={this.state.type} >
                        <option value="VIDEO">VIDEO</option>
                        <option value="TEXT">TEXT</option>
                        <option value="YOUTUBE">YOUTUBE</option>
                    </select>
                </div>
                <div className="form-group">
                    <label >{t('admin:lesson.isPublic')}</label>
                    <select className="form-control" onChange={this.setLessonIsPublic}
                        value={this.state.isPublic} >
                        <option value="true">Public</option>
                        <option value="false">Private</option>

                    </select>
                </div>
                <div className="form-group">
                    <label >{t('admin:lesson.name')}</label>
                    <input type="text" className="form-control" onChange={this.setLessonName}
                        value={this.state.name} placeholder={t('admin:lesson.name')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:lesson.imageUrl')}</label>
                    <input type="file" name="imageFile" className="form-control-file" onChange={this.setLessonImageFile}
                        required accept="image/*" />
                </div>
                <div className="form-group">
                    <label >{t('admin:lesson.videoUrl')}</label>
                    <input type="text" className="form-control" onChange={this.setLessonVideoUrl}
                        value={this.state.videoUrl} placeholder={t('admin:lesson.videoUrl')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:lesson.timeAmount')}</label>
                    <input type="text" className="form-control" onChange={this.setLessonTimeAmount}
                        value={this.state.timeAmount} placeholder={t('admin:lesson.timeAmount')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:lesson.metaKeyword')}</label>
                    <input type="text" className="form-control" onChange={this.setLessonMetaKeyWord}
                        value={this.state.metaKeyword} placeholder={t('admin:lesson.metaKeyword')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:lesson.metaDescription')}</label>
                    <textarea className="form-control" onChange={this.setLessonMetaDescription}
                        value={this.state.metaDescription} placeholder={t('admin:lesson.metaDescription')} required ></textarea>
                </div>
                <div className="form-group">
                    <label >{t('admin:lesson.sourceCode')}</label>
                    <input type="text" className="form-control" onChange={this.setSourceCode}
                        value={this.state.sourceCode} placeholder={t('admin:lesson.sourceCode')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:lesson.description')}</label>
                    <Editor apiKey="rhk66ixiw1z5jxhvf5o22ouhtq5l3vh1yj8jq3xzgycsd1hd"
                        initialValue={this.state.description}
                        init={tinyConfig}
                        onEditorChange={this.setLessonDescription}
                    />
                </div>


            </div>
            <div className="card-footer">
                <button type="button" onClick={this.submit} className="btn btn-info">{t('button.save')}</button>
            </div>
            <AlertBlockUI {...this.state} setState={(status) => this.setState(status)} />
        </form>
    }
}
export default withRouter(withTranslation()(LessonAdd));
