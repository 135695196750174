import React from 'react';
import { useTranslation } from 'react-i18next';
import { Route, Switch } from 'react-router-dom';

import PageMeta from '../PageMeta';
import { AdminRoutes } from './AdminRoutes';
import Footer from './shared/Footer';
import PageMenu from './shared/PageMenu';

function Index() {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <PageMenu />
            <Switch>
                {AdminRoutes.map((route, index) => (
                    <Route
                        key={index}
                        path={route.path}
                        exact={route.exact}
                        render={() => {
                            return (
                                <React.Fragment>
                                    <PageMeta title={t(route.title)} description={t(route.description)} />
                                    <route.main />
                                </React.Fragment>)
                        }}
                    />
                ))}
            </Switch>
            <Footer />
        </React.Fragment>
    );
}

export default Index;
