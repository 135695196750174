import { Editor } from '@tinymce/tinymce-react';
import React, { Component, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import getSlug from 'speakingurl/lib/speakingurl';

import API from '../../API';
import AuthVerification from '../../AuthVerification';
import AlertBlockUI from '../shared/AlertBlockUI';
import { handleError } from '../shared/ResponseHandler';
import { tinyConfig } from '../shared/TinyConfig';

class LearnPathEdit extends React.Component {

    render() {
        return (<div className="content-wrapper">
            <Breadcrumb />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-secondary">
                                <FormHeader />
                                <FormBody {...this.props} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        )
    }
}

const Breadcrumb = () => {
    const { t } = useTranslation();
    return <section className="content-header">
        <div className="container-fluid">
            <div className="row mb-2">
                <div className="col-sm-6">
                    <h1><Link to="/admin/learn-path"><i className="fas fa-arrow-left fa-sm" /> </Link>  {t('admin:learnPath.edit.title')}</h1>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/admin">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/admin/learn-path">{t('admin:learnPath.edit.title')}</Link></li>
                        <li className="breadcrumb-item active">{t('admin:learnPath.edit.title')}</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
}

const FormHeader = () => {
    const { t } = useTranslation()
    return <div className="card-header">
        <h3 className="card-title">{t('admin:learnPath.edit.title')}</h3>
    </div>
}

class FormBody extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: 0,
            status: 'new',
            courseName: '',
            description: '',
            price: 0,
            pathStatus:'',
            metaKeyword: '',
            metaDescription: '',
            imageUrl: "",
            courseCategoryDTO: {},
            levelDTO: {},
            imageFile: null
        }
        this.editor = React.createRef();
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        const { match } = this.props;
        var id = match.params.id;
        var resp = await API.authorizedJSONGET("/api/teacher/learn-path/" + id);
        if (resp.ok) {
            var course = await resp.json();
            this.setState(course);
            this.editor.current.editor.setContent(course.description);
        }
    }

    loadCategoryData = async () => {
        var resp = await API.authorizedJSONPost("/api/category/search", this.state);

        if (resp.status == 200) {
            var json = await resp.json();
            this.setState({ responseCategoryData: json })
        } else {
            handleError(resp, this)
        }
    }

    loadCategoryOptions = async (inputValue) => {
        this.setState({ keyword: inputValue })
        await this.loadCategoryData();
        const options = [];
        this.state.responseCategoryData.data.forEach(item => {
            options.push({ value: item, label: item.categoryName })
        })
        return options
    };

    setCourseMetaKeyword = (event) => this.setState({ metaKeyword: event.target.value })
    setCourseMetaDescription = (event) => this.setState({ metaDescription: event.target.value })
    setCourseDescription = (event) => this.setState({ description: event })
    setPathName = (event) => this.setState({ name: event.target.value })
    setPathStatus = (event) => this.setState({ pathStatus: event.target.value })
    setCoursePrice = (event) => this.setState({ price: event.target.value })
    setCourseImageFile = (event) => this.setState({ imageFile: event.target.files[0] })

    submit = async () => {
        if (this.state.imageFile) {
            const oldImageUrl = this.state.imageUrl
            const folderImage = 'path/' + AuthVerification.currentUser().uid + "/"
            const name = getSlug(this.state.courseName) + "-" + new Date().getTime()
            const imageUrl = await API.firebaseFileUpload(this.state.imageFile, folderImage + name)
            this.setState({ imageUrl: imageUrl, oldImageUrl: oldImageUrl }, this.addToBackend)
        } else {
            this.addToBackend()
        }
    }

    addToBackend = async () => {
        var resp = await API.authorizedJSONPut("/api/teacher/learn-path/update", this.state);
        if (resp.ok) {
            if (this.state.oldImageUrl) {
                const url = this.state.oldImageUrl
                await API.firebaseFileDelete(decodeURIComponent(url.substring(url.indexOf("path"), url.indexOf("?"))))
            }
            this.setState({ status: "ok" })
        } else {
            handleError(resp, this)
        }
    }

    render() {
        const { t } = this.props;

        return <form role="form">
            <div className="card-body">
                <div className="form-group">
                    <label>#</label>
                    <input type="text" className="form-control"
                        value={this.state.id} disabled />
                </div>
                <div className="form-group">
                    <label >{t('admin:learnPath.name')}</label>
                    <input type="text" className="form-control" onChange={this.setPathName}
                        value={this.state.name} placeholder={t('admin:learnPath.name')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:learnPath.price')}</label>
                    <input type="number" className="form-control" onChange={this.setCoursePrice}
                        value={this.state.price} placeholder={t('admin:learnPath.price')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:learnPath.imageUrl')}</label>
                    <input type="file" name="imageFile" className="form-control-file" onChange={this.setCourseImageFile}
                        placeholder={t('admin:learnPath.imageUrl')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:learnPath.pathStatus')}</label>
                    <select className="form-control" onChange={this.setPathStatus} 
                        value={this.state.pathStatus} >
                        <option value="DRAFT">DRAFT</option>
                        <option value="PUBLISH">PUBLISH</option>
                    </select>
                </div>
                <div className="form-group">
                    <label className="col-form-label">{t('admin:learnPath.category')}</label>
                    <div className="col-md-12">
                        <AsyncSelect
                            cacheOptions
                            loadOptions={this.loadCategoryOptions}
                            defaultOptions
                            isClearable={true}
                            isSearchable={true}
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    this.setState({ courseCategoryDTO: { id: selectedOption.value.id } })
                                } else {
                                    this.setState({ courseCategoryDTO: null })
                                }
                            }}
                        />
                    </div>
                </div>

                <div className="form-group">
                    <label >{t('admin:learnPath.metaKeyword')}</label>
                    <input type="text" className="form-control" onChange={this.setCourseMetaKeyword}
                        value={this.state.metaKeyword} placeholder={t('admin:learnPath.metaKeyword')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:learnPath.metaDescription')}</label>
                    <textarea type="text" className="form-control" onChange={this.setCourseMetaDescription}
                        value={this.state.metaDescription} placeholder={t('admin:learnPath.metaDescription')} required ></textarea>
                </div>
                <div className="form-group">
                    <label >{t('admin:learnPath.description')}</label>
                    <Editor apiKey="rhk66ixiw1z5jxhvf5o22ouhtq5l3vh1yj8jq3xzgycsd1hd"
                        initialValue={this.state.description}
                        init={tinyConfig}
                        ref={this.editor}
                        onEditorChange={this.setCourseDescription}
                    />
                </div>
            </div>
            <div className="card-footer">
                <button type="button" onClick={this.submit} className="btn btn-info">{t('button.save')}</button>
            </div>
            <AlertBlockUI {...this.state} setState={(status) => this.setState(status)} />
        </form>
    }
}

export default withRouter(withTranslation()(LearnPathEdit));