import React, { Component, useState } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import API from '../../API';
import AlertBlockUI from '../shared/AlertBlockUI';
import { handleError } from '../shared/ResponseHandler';

class LevelAdd extends React.Component {
    render() {
        return (
            <div className="card card-secondary">
                <FormHeader />
                <FormBody {...this.props} />
            </div>
        );
    }
}
const FormHeader = () => {
    const { t } = useTranslation()
    return <div className="card-header">
        <h3 className="card-title">{t('admin:level.add.title')}</h3>
    </div>
}

class FormBody extends Component {
    constructor(props) {
        super(props)
        this.state = {
            levelName: ''
        }
    }

    setLevelName = (event) => this.setState({ levelName: event.target.value })

    submit = async () => {
        var resp = await API.authorizedJSONPost("/api/admin/level/add", this.state);
        if (resp.ok) {
            this.setState({ status: "ok" })
            this.props.loadData()
        } else {
            handleError(resp, this)
        }
    }

    render() {
        const { t } = this.props;

        return <form >
            <div className="card-body">
                <div className="form-group">
                    <label >{t('admin:level.name')}</label>
                    <input type="text" className="form-control" onChange={this.setLevelName}
                        value={this.state.levelName} placeholder={t('admin:level.name')} required />
                </div>
            </div>
            <div className="card-footer">
                <button type="button" onClick={this.submit} className="btn btn-info">{t('button.save')}</button>
            </div>
            <AlertBlockUI {...this.state} setState={(status) => this.setState(status)} />
        </form>
    }
}
export default withRouter(withTranslation()(LevelAdd));