import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class AlertBlockUI extends Component {
    hideAlert = () => {
        this.timer = window.setTimeout(() => this.props.setState({ status: 'new' }), 5000)
    }

    render() {
        clearTimeout(this.timer)
        this.hideAlert()
        const { t } = this.props
        return (
            <div className="toasts-top-right fixed" >
                {(this.props.status === 'ok') &&
                    <div className="toast bg-success fade show" role="alert" aria-live="assertive" aria-atomic="true">
                        <div className="toast-header"><strong className="mr-auto">{t('message.success')}</strong>
                            <button data-dismiss="toast" type="button" className="ml-2 mb-1 close" aria-label="Close" onClick={() => this.props.setState({ status: 'new' })}>
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="toast-body">{t('message.success.description')}</div>
                    </div>
                }
                {(this.props.status === 'error') &&
                    <div className="toast bg-danger fade show" role="alert" aria-live="assertive" aria-atomic="true">
                        <div className="toast-header"><strong className="mr-auto">{t('message.error')}</strong>
                            <button data-dismiss="toast" type="button" className="ml-2 mb-1 close" aria-label="Close" onClick={() => this.props.setState({ status: 'new' })}>
                                <span aria-hidden="true">×</span></button>
                        </div>
                        <div className="toast-body">{t(this.props.message)}</div>
                    </div>
                }
            </div>
        )
    }
}

export default withTranslation()(AlertBlockUI)