import React from 'react';

function Footer() {
    return (
        <footer className="main-footer">
            <div className="container-fluid">
                <div className="float-right d-none d-sm-block">2015 - {new Date().getFullYear()}</div>
                <strong>Copyright &copy; <a href="https://trungtamjava.com">J Master</a> </strong> All rights reserved.
            </div>
        </footer>
    );
}

export default Footer;