import React, { Component, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import AsyncSelect from 'react-select/async';

import API from '../../API';
import AlertBlockUI from '../shared/AlertBlockUI';
import { handleError } from '../shared/ResponseHandler';

class PreCourseAdd extends React.Component {
    render() {
        return (
            <div className="card card-secondary">
                <FormHeader />
                <FormBody {...this.props} />
            </div>
        );
    }
}
const FormHeader = () => {
    const { t } = useTranslation()
    return <div className="card-header">
        <h3 className="card-title">{t('admin:precourse.add.title')}</h3>
    </div>
}

class FormBody extends Component {
    constructor(props) {
        super(props)
        const { match } = this.props;
        var courseId = match.params.id;
        this.state = {
            courseDTO: { id: courseId },
            //search
            start: 0,
            length: 10,
            keyword: "",
        }
    }

    setSortOrder = (event) => this.setState({ sortOrder: event.target.value })

    loadPreCourseData = async () => {
        var resp = await API.authorizedJSONPost("/api/teacher/course/search", this.state);

        if (resp.status == 200) {
            var json = await resp.json();
            this.setState({ responsePreCourseData: json })
        } else {
            handleError(resp, this)
        }
    }

    loadPreCourseOptions = async (inputValue) => {
        this.setState({ keyword: inputValue })
        await this.loadPreCourseData();
        const options = [];
        this.state.responsePreCourseData.data.forEach(item => {
            options.push({ value: item, label: item.courseName })
        })
        return options
    };

    addPreCourse = async () => {
        console.log(this.state)
        var resp = await API.authorizedJSONPost("/api/teacher/pre-course/add", this.state);
        if (resp.ok) {
            this.setState({ status: "ok" })
            this.props.loadData()
        } else {
            handleError(resp, this)
        }
    }

    render() {
        const { t } = this.props;

        return <form role="form" id="form">
            <div className="card-body">
                <div className="form-group">
                    <label >{t('admin:course.courseSection.sortOrder')}</label>
                    <input type="text" className="form-control" onChange={this.setSortOrder}
                        value={this.state.sortOrder} placeholder={t('admin:course.courseSection.sortOrder')} required />
                </div>

                <div className="form-group">
                    <label className="col-form-label">{t('admin:course.name')}</label>
                    <div className="col-md-12">
                        <AsyncSelect
                            cacheOptions
                            loadOptions={this.loadPreCourseOptions}
                            defaultOptions
                            isClearable={true}
                            isSearchable={true}
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    this.setState({ preCourseDTO: { id: selectedOption.value.id } })
                                } else {
                                    this.setState({ preCourseDTO: null })
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="card-footer">
                <button type="button" onClick={this.addPreCourse} className="btn btn-info">{t('button.save')}</button>
            </div>
            <AlertBlockUI {...this.state} setState={(status) => this.setState(status)} />
        </form>
    }
}
export default withRouter(withTranslation()(PreCourseAdd));