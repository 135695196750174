import React, { Component, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';

import API from '../../API';
import AlertBlockUI from '../shared/AlertBlockUI';
import { handleError } from '../shared/ResponseHandler';

class CourseSectionEdit extends React.Component {

    render() {
        return (<div className="content-wrapper">
            <Breadcrumb />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-secondary">
                                <FormHeader />
                                <FormBody {...this.props} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        )
    }
}

const Breadcrumb = () => {
    const { t } = useTranslation();
    return <section className="content-header">
        <div className="container-fluid">
            <div className="row mb-2">
                <div className="col-sm-6">
                    <h1><a onClick={() => window.history.back()}><i className="fas fa-arrow-left fa-sm" /> </a>  {t('admin:course.courseSection.edit.title')}</h1>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/admin">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/admin/course">{t('admin:meta.course.title')}</Link></li>
                        <li className="breadcrumb-item active">{t('admin:course.courseSection.edit.title')}</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
}

const FormHeader = () => {
    const { t } = useTranslation()
    return <div className="card-header">
        <h3 className="card-title">{t('admin:course.courseSection.edit.title')}</h3>
    </div>
}

class FormBody extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: 0,
            status: 'new',
            name: '',
            sortOrder: 1,
        }
        this.editor = React.createRef();
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        const { match } = this.props;
        var id = match.params.id;
        var resp = await API.authorizedJSONGET("/api/teacher/course-section/" + id);
        if (resp.ok) {
            console.log(resp)
            var course = await resp.json();
            this.setState(course);
        } else {
            handleError(resp, this)
        }
    }

    setCourseSectionName = (event) => this.setState({ name: event.target.value })
    setCourseSortOrder = (event) => this.setState({ sortOrder: event.target.value })

    submit = async () => {
        this.addToBackend()
    }

    addToBackend = async () => {
        var resp = await API.authorizedJSONPut("/api/teacher/course-section/update", this.state);
        if (resp.ok) {
            this.setState({ status: "ok" })
        } else {
            handleError(resp, this)
        }
    }

    render() {
        const { t } = this.props;

        return <form role="form">
            <div className="card-body">
                <div className="form-group">
                    <label>#</label>
                    <input type="text" className="form-control"
                        value={this.state.id} disabled />
                </div>
                <div className="form-group">
                    <label >{t('admin:course.courseSection.courseSectionName')}</label>
                    <input type="text" className="form-control" onChange={this.setCourseSectionName}
                        value={this.state.name} placeholder={t('admin:course.courseSection.courseSectionName')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:course.courseSection.sortOrder')}</label>
                    <input type="text" className="form-control" onChange={this.setCourseSortOrder}
                        value={this.state.sortOrder} placeholder={t('admin:course.courseSection.sortOrder')} required />
                </div>
            </div>
            <div className="card-footer">
                <button type="button" onClick={this.submit} className="btn btn-info">{t('button.save')}</button>
            </div>
            <AlertBlockUI {...this.state} setState={(status) => this.setState(status)} />
        </form>
    }
}

export default withRouter(withTranslation()(CourseSectionEdit));