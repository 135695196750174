import React from 'react';


function Maintain() {
  return (
    <div id="wrapper">
      <div data-uk-height-viewport="offset-top: true; offset-bottom: true"
        className="uk-flex uk-flex-middle bg-gradient-grey uk-text-center  px-4">
        <div className="container-small m-auto ">

          <div className="uk-light mb-lg-8">
            <h1>We're coming <strong>soon</strong></h1>
            <p className="mb-0"> We apologize for the inconvenience but Masterkit is currently <br
              className="uk-visible@s" />
                        undergoing
                        planned maintenance.</p>
          </div>
          <div className="uk-grid-small uk-child-width-auto@s uk-child-width-1-4 uk-margin countdown" data-uk-grid
            data-uk-countdown="date: 2020-10-07T08:32:06+00:00">
            <div>
              <div className="box">
                <div className="uk-countdown-number uk-countdown-days"></div>
                <div className="countdown-text">
                  <p> DAYS</p>
                </div>
              </div>
            </div>
            <div>
              <div className="box">
                <div className="uk-countdown-number uk-countdown-hours"></div>
                <div className="countdown-text">
                  <p> HOURS</p>
                </div>
              </div>
            </div>
            <div>
              <div className="box">
                <div className="uk-countdown-number uk-countdown-minutes"></div>
                <div className="countdown-text">
                  <p> MINUTES</p>
                </div>
              </div>
            </div>
            <div>
              <div className="box">
                <div className="uk-countdown-number uk-countdown-seconds"></div>
                <div className="countdown-text">
                  <p> SECONDS</p>
                </div>
              </div>
            </div>
          </div>

          <form className="uk-grid-small uk-width-4-5@m m-auto mt-lg-6  mt-4 countdown-form" data-uk-grid>
            <div className="uk-width-expand  pl-0">
              <input type="text" className="uk-input uk-form-small" style={{ border: 0 }} placeholder="Your email address" />
            </div>
            <div className="uk-width-1-3@s uk-width-auto pl-1">
              <input type="submit" value="Subscribe" className="button circle block" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Maintain;
