import React from 'react';
import { Helmet } from 'react-helmet';

const PageMeta = ({ title, description }) => {
    var defaultTitle = 'Trung Tâm J Master';
    return (
        <Helmet>
            <title>{title ? (title + ' | ' + defaultTitle) : defaultTitle}</title>
            <meta name="description" content={description} />
        </Helmet>
    );
};

export default PageMeta;