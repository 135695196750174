import React, { Component, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import getSlug from 'speakingurl';
import API from '../../API';
import AlertBlockUI from '../shared/AlertBlockUI';
import { handleError } from '../shared/ResponseHandler';

class CourseCategoryEdit extends React.Component {

    render() {
        return (<div className="content-wrapper">
            <Breadcrumb />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-secondary">
                                <FormHeader />
                                <FormBody {...this.props} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        )
    }
}

const Breadcrumb = () => {
    const { t } = useTranslation();
    return <section className="content-header">
        <div className="container-fluid">
            <div className="row mb-2">
                <div className="col-sm-6">
                    <h1><Link to="/admin/category"><i className="fas fa-arrow-left fa-sm" /> </Link>  {t('admin:courseCategory.edit.title')}</h1>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/admin">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/admin/category">{t('admin:meta.courseCategory.title')}</Link></li>
                        <li className="breadcrumb-item active">{t('admin:courseCategory.edit.title')}</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
}

const FormHeader = () => {
    const { t } = useTranslation()
    return <div className="card-header">
        <h3 className="card-title">{t('admin:courseCategory.edit.title')}</h3>
    </div>
}

class FormBody extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categoryName: '',
            description: '',
            sortOrder: '',
            parentCategoryId: null,

        }
    }

    componentDidMount() {
        this.loadData();
    }


    loadData = async () => {
        const { match } = this.props;
        var id = match.params.id;
        var resp = await API.authorizedJSONGET("/api/admin/category/" + id);
        if (resp.ok) {
            var level = await resp.json();
            this.setState(level);
        } else {
            handleError(resp, this)
        }
    }

    loadParentCategoryData = async () => {
        var resp = await API.authorizedJSONPost("/api/admin/category/search", this.state);

        if (resp.status == 200) {
            var json = await resp.json();
            this.setState({ responseData: json })
        } else {
            handleError(resp, this)
        }
    }

    loadOptions = async (inputValue) => {
        this.setState({ keyword: inputValue })
        await this.loadParentCategoryData();
        const options = [];
        this.state.responseData.data.forEach(item => {
            options.push({ value: item, label: item.categoryName })
        })
        return options
    };
    setCourseCategoryName = (event) => this.setState({ categoryName: event.target.value })
    setDescription = (event) => this.setState({ description: event.target.value })
    setSortOrder = (event) => this.setState({ sortOrder: event.target.value })
    setParentCategory = (event) => this.setState({ parentCategoryId: event.target.value })
    setCourseCategoryImageFile = (event) => this.setState({ imageFile: event.target.files[0] })
    submit = async () => {
        if (this.state.imageFile) {
            const oldImageUrl = this.state.imageUrl
            const folderImage = 'category/'
            const name = getSlug(this.state.name) + "-" + new Date().getTime()
            const imageUrl = await API.firebaseFileUpload(this.state.imageFile, folderImage + name)
            this.setState({ image: imageUrl, oldImageUrl: oldImageUrl }, this.addCourseCategoryToBackend)
        } else {
            this.addCourseCategoryToBackend()
        }
    }
    addCourseCategoryToBackend = async () => {
        var resp = await API.authorizedJSONPut("/api/admin/category/update", this.state);
        if (resp.ok) {
            if (this.state.oldImageUrl) {
                const url = this.state.oldImageUrl
                await API.firebaseFileDelete(decodeURIComponent(url.substring(url.indexOf("category"), url.indexOf("?"))))
            }
            this.setState({ status: "ok" })
        } else {
            handleError(resp, this)
        }
    }

    render() {
        const { t } = this.props;

        return <form >
            <div className="card-body">
                <div className="form-group">
                    <label>#</label>
                    <input type="text" className="form-control"
                        value={this.state.id} disabled />
                </div>
                <div className="form-group">
                    <label >{t('admin:courseCategory.name')}</label>
                    <input type="text" className="form-control" onChange={this.setCourseCategoryName}
                        value={this.state.categoryName} placeholder={t('admin:courseCategory.name')} />
                </div>
                <div className="form-group">
                    <label >{t('admin:courseCategory.sortOrder')}</label>
                    <input type="number" className="form-control" onChange={this.setSortOrder}
                        value={this.state.sortOrder} placeholder={t('admin:courseCategory.sortOrder')} />
                </div>
                <div className="form-group">
                    <label >{t('admin:courseCategory.image')}</label>
                    <input type="file" name="imageFile" className="form-control-file" onChange={this.setCourseCategoryImageFile}
                        required accept="image/*" />
                </div>
                {/* <div className="form-group">
                    <label className="col-form-label">{t('admin:courseCategory.parentCategory')}</label>
                    <AsyncSelect
                        cacheOptions
                        loadOptions={this.loadOptions}
                        defaultOptions
                        isClearable={true}
                        isSearchable={true}
                        onChange={(selectedOption) => {
                            if (selectedOption) {
                                this.setState({ parentCategoryId: selectedOption.value.id })
                            } else {
                                this.setState({ parentCategoryId: null })
                            }
                        }}
                    />
                </div> */}
                <div className="form-group">
                    <label >{t('admin:courseCategory.description')}</label>
                    <textarea className="form-control" onChange={this.setDescription}
                        value={this.state.description} ></textarea>
                </div>
            </div>
            <div className="card-footer">
                <button type="button" onClick={this.submit} className="btn btn-info">{t('button.save')}</button>
            </div>
            <AlertBlockUI {...this.state} setState={(status) => this.setState(status)} />
        </form>
    }
}

export default withRouter(withTranslation()(CourseCategoryEdit));