import { Editor } from '@tinymce/tinymce-react';
import React, { Component, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import getSlug from 'speakingurl/lib/speakingurl';

import API from '../../API';
import AuthVerification from '../../AuthVerification';
import AlertBlockUI from '../shared/AlertBlockUI';
import { handleError } from '../shared/ResponseHandler';
import { tinyConfig } from '../shared/TinyConfig';

class CourseEdit extends React.Component {

    render() {
        return (<div className="content-wrapper">
            <Breadcrumb />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-secondary">
                                <FormHeader />
                                <FormBody {...this.props} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        )
    }
}

const Breadcrumb = () => {
    const { t } = useTranslation();
    return <section className="content-header">
        <div className="container-fluid">
            <div className="row mb-2">
                <div className="col-sm-6">
                    <h1><Link to="/admin/course"><i className="fas fa-arrow-left fa-sm" /> </Link>  {t('admin:course.edit.title')}</h1>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/admin">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/admin/course">{t('admin:meta.course.title')}</Link></li>
                        <li className="breadcrumb-item active">{t('admin:course.edit.title')}</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
}

const FormHeader = () => {
    const { t } = useTranslation()
    return <div className="card-header">
        <h3 className="card-title">{t('admin:course.edit.title')}</h3>
    </div>
}

const listStatus=["DRAFT","PUBLISH"];
class FormBody extends Component {
    constructor(props) {
        super(props)
        this.state = {
            id: 0,
            status: 'new',
            courseName: '',
            description: '',
            courseStatus:'',
            price: 0,
            metaKeyword: '',
            metaDescription: '',
            imageUrl: "",
            courseCategoryDTO: {},
            levelDTO: {},
            imageFile: null,
            supportFeatures: { data: [] },
            selectedSupportFeatureIds: [],
            teachers: { data: [] },
            selectedTeacherIds: []
        }
        this.editor = React.createRef();
    }

    loadSupportFeature = async () => {
        var resp = await API.authorizedJSONPost("/api/teacher/support/search", { start: null });

        if (resp.ok) {
            var json = await resp.json();
            this.setState({ supportFeatures: json })
        } else {
            handleError(resp, this)
        }
    }
    loadTeacher = async () => {
        var resp = await API.authorizedJSONPost("/api/admin/teacher/search", { start: null });

        if (resp.status == 200) {
            var json = await resp.json();
            this.setState({ teachers: json })

        } else {
            handleError(resp, this)
        }
    }

    componentDidMount() {
        this.loadData();
        this.loadSupportFeature();
        this.loadTeacher()
    }
    setTeacher = (event) => {
        var options = event.target.options;
        var teacherDTOs = [];
        let selectedTeacherIds = []
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                teacherDTOs.push({ id: options[i].value });
                selectedTeacherIds.push(options[i].value)
            }
        }
        this.setState({ teacherDTOs, selectedTeacherIds });
    }
    loadData = async () => {
        const { match } = this.props;
        var id = match.params.id;
        var resp = await API.authorizedJSONGET("/api/teacher/course/" + id);
        if (resp.ok) {
            var course = await resp.json();
            this.setState(course);
            this.editor.current.editor.setContent(course.description);

            const selectedSupportFeatureIds = course.supportFeatureDTOs.map(item => {
                return item.id
                
            })
            const selectedTeacherIds = course.teacherDTOs.map(item => {
                return item.id})
            this.setState({ selectedSupportFeatureIds ,selectedTeacherIds})
        } else {
            handleError(resp, this)
        }
    }

    loadCategoryData = async () => {
        var resp = await API.authorizedJSONPost("/api/category/search", this.state);

        if (resp.status == 200) {
            var json = await resp.json();
            this.setState({ responseCategoryData: json })
        } else {
            handleError(resp, this)
        }
    }

    loadStatusOptions = async (inputValue) => {
        const options = [];
        listStatus.forEach(item => {
            options.push({ value: item, label: item })
        })
        return options
    };
    loadCategoryOptions = async (inputValue) => {
        this.setState({ keyword: inputValue })
        await this.loadCategoryData();
        const options = [];
        this.state.responseCategoryData.data.forEach(item => {
            options.push({ value: item, label: item.categoryName })
        })
        return options
    };

    loadLevelData = async () => {
        var resp = await API.authorizedJSONPost("/api/level/search", this.state);

        if (resp.status == 200) {
            var json = await resp.json();
            this.setState({ responseLevelData: json })
        } else {
            handleError(resp, this)
        }
    }

    loadLevelOptions = async (inputValue) => {
        this.setState({ keyword: inputValue })
        await this.loadLevelData();
        const options = [];
        this.state.responseLevelData.data.forEach(item => {
            options.push({ value: item, label: item.levelName })
        })
        return options
    };

    setCourseMetaKeyword = (event) => this.setState({ metaKeyword: event.target.value })
    setCourseMetaDescription = (event) => this.setState({ metaDescription: event.target.value })
    setCourseDescription = (event) => this.setState({ description: event })
    setCourseName = (event) => this.setState({ courseName: event.target.value })
    setCoursePrice = (event) => this.setState({ price: event.target.value })
    setCourseImageFile = (event) => this.setState({ imageFile: event.target.files[0] })

    setSupportFeatures = (event) => {
        var options = event.target.options;
        var supportFeatureDTOs = [];
        let selectedSupportFeatureIds = []
        for (var i = 0, l = options.length; i < l; i++) {
            if (options[i].selected) {
                supportFeatureDTOs.push({ id: options[i].value });
                selectedSupportFeatureIds.push(options[i].value)
            }
        }
        this.setState({ supportFeatureDTOs, selectedSupportFeatureIds });
    }

    submit = async () => {
        if (this.state.imageFile) {
            const oldImageUrl = this.state.imageUrl
            const folderImage = 'course/' + AuthVerification.currentUser().uid + "/"
            const name = getSlug(this.state.courseName) + "-" + new Date().getTime()
            const imageUrl = await API.firebaseFileUpload(this.state.imageFile, folderImage + name)
            this.setState({ imageUrl: imageUrl, oldImageUrl: oldImageUrl }, this.addToBackend)
        } else {
            this.addToBackend()
        }
    }

    addToBackend = async () => {
        var resp = await API.authorizedJSONPut("/api/teacher/course/update", this.state);
        if (resp.ok) {
            if (this.state.oldImageUrl) {
                const url = this.state.oldImageUrl
                await API.firebaseFileDelete(decodeURIComponent(url.substring(url.indexOf("course"), url.indexOf("?"))))
            }
            this.setState({ status: "ok" })
        } else {
            handleError(resp, this)
        }
    }

    render() {
        const { t } = this.props;

        return <form role="form">
            <div className="card-body">
                <div className="form-group">
                    <label>#</label>
                    <input type="text" className="form-control"
                        value={this.state.id} disabled />
                </div>
                <div className="form-group">
                    <label >{t('admin:course.name')}</label>
                    <input type="text" className="form-control" onChange={this.setCourseName}
                        value={this.state.courseName} placeholder={t('admin:course.name')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:course.price')}</label>
                    <input type="number" className="form-control" onChange={this.setCoursePrice}
                        value={this.state.price} placeholder={t('admin:course.price')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:course.imageUrl')}</label>
                    <input type="file" name="imageFile" className="form-control-file" onChange={this.setCourseImageFile}
                        placeholder={t('admin:course.imageUrl')} required />
                </div>
                <div className="form-group">
                    <label className="col-form-label">{t('admin:course.status')}</label>
                    <div className="col-md-12">
                        <AsyncSelect
                            cacheOptions
                            loadOptions={this.loadStatusOptions}
                            defaultOptions
                            isClearable={true}
                            isSearchable={true}
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    this.setState({ courseStatus:  selectedOption.value })
                                } else {
                                    this.setState({ courseStatus: "DRAFT" })
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-form-label">{t('admin:course.category')}</label>
                    <div className="col-md-12">
                        <AsyncSelect
                            cacheOptions
                            loadOptions={this.loadCategoryOptions}
                            defaultOptions
                            isClearable={true}
                            isSearchable={true}
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    this.setState({ courseCategoryDTO: { id: selectedOption.value.id } })
                                } else {
                                    this.setState({ courseCategoryDTO: null })
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label className="col-form-label">{t('admin:course.level')}</label>
                    <div className="col-md-12">
                        <AsyncSelect
                            cacheOptions
                            loadOptions={this.loadLevelOptions}
                            defaultOptions
                            isClearable={true}
                            isSearchable={true}
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    this.setState({ levelDTO: { id: selectedOption.value.id } })
                                } else {
                                    this.setState({ levelDTO: null })
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="form-group">
                    <label >{t('admin:course.metaKeyword')}</label>
                    <input type="text" className="form-control" onChange={this.setCourseMetaKeyword}
                        value={this.state.metaKeyword} placeholder={t('admin:course.metaKeyword')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:course.metaDescription')}</label>
                    <textarea type="text" className="form-control" onChange={this.setCourseMetaDescription}
                        value={this.state.metaDescription} placeholder={t('admin:course.metaDescription')} required ></textarea>
                </div>
                <div className="form-group">
                    <label >{t('admin:course.supportfeature')}</label>
                    <select className="form-control" onChange={this.setSupportFeatures} multiple
                        value={this.state.selectedSupportFeatureIds} >
                        {this.state.supportFeatures.data.map((item) => {
                            return <option value={item.id} key={item.id}>{item.feature}</option>
                        })}
                    </select>
                </div>
                <div className="form-group">
                    <label >{t('admin:account.teacher')}</label>
                    <select className="form-control" onChange={this.setTeacher} multiple
                        value={this.state.selectedTeacherIds} >
                        {this.state.teachers.data.map((item) => {
                            return <option value={item.id} key={item.id}>{item.name}</option>
                        })}
                    </select>
                </div>
                <div className="form-group">
                    <label >{t('admin:course.description')}</label>
                    <Editor apiKey="rhk66ixiw1z5jxhvf5o22ouhtq5l3vh1yj8jq3xzgycsd1hd"
                        initialValue={this.state.description}
                        init={tinyConfig}
                        ref={this.editor}
                        onEditorChange={this.setCourseDescription}
                    />
                </div>
            </div>
            <div className="card-footer">
                <button type="button" onClick={this.submit} className="btn btn-info">{t('button.save')}</button>
            </div>
            <AlertBlockUI {...this.state} setState={(status) => this.setState(status)} />
        </form>
    }
}

export default withRouter(withTranslation()(CourseEdit));