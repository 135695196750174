import React, { Component, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import AsyncSelect from 'react-select/async';

import API from '../../API';
import AlertBlockUI from '../shared/AlertBlockUI';
import { handleError } from '../shared/ResponseHandler';

class UserGroupInfoAdd extends React.Component {

    render() {
        return (
            <div className="card card-secondary">
                <FormHeader />
                <FormBody {...this.props} />
            </div>
        );
    }
}
const FormHeader = () => {
    const { t } = useTranslation()
    return <div className="card-header">
        <h3 className="card-title">{t('admin:course.courseSectionLesson.add.title')}</h3>
    </div>
}

class FormBody extends Component {
    constructor(props) {
        super(props)

        this.state = {
            //search
            start: 0,
            length: 10,
            keyword: "",
            accountId: null
        }
    }

    componentDidMount() {
        this.loadAccountData()
    }

    loadAccountData = async () => {
        var resp = await API.authorizedJSONPost("/api/admin/account/search", this.state);

        if (resp.status == 200) {
            var json = await resp.json();
            this.setState({ listAccount: json.data })
        }
    }

    loadAccountDataOptions = async (inputValue) => {
        this.setState({ keyword: inputValue })
        await this.loadAccountData();
        const options = [];
        this.state.listAccount.forEach(item => {
            options.push({ value: item, label: item.email })
        })
        return options
    };

    submit = async () => {
        const { match } = this.props;
        var userGroupId = match.params.id;

        var resp = await API.authorizedJSONPut("/api/admin/user-group/account/add", { ...this.state, userGroupId });
        if (resp.ok) {
            this.setState({ status: "ok" })
            this.props.loadData()
        } else {
            handleError(resp, this)
        }
    }

    render() {
        const { t } = this.props;
        return <form role="form" id="form">
            <div className="card-body">
                <div className="form-group">
                    <label >{t('userGroup.info.add')}</label>
                    <AsyncSelect
                        cacheOptions
                        loadOptions={this.loadAccountDataOptions}
                        defaultOptions
                        isClearable={true}
                        isSearchable={true}
                        onChange={(selectedOption) => {
                            if (selectedOption) {
                                this.setState({ accountId: selectedOption.value.id })
                            } else {
                                this.setState({ accountId: null })
                            }
                        }}
                    />
                </div>
            </div>
            <div className="card-footer">
                <button type="button" onClick={this.submit} className="btn btn-info">{t('button.save')}</button>
            </div>
            <AlertBlockUI {...this.state} setState={(status) => this.setState(status)} />
        </form>
    }
}
export default withRouter(withTranslation()(UserGroupInfoAdd));