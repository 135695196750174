import React from 'react';

import NotFound from '../NotFound';
import UpdateTeacher from './account/UpdateTeacher';
import User from './account/User';
import UserAdd from './account/UserAdd';
import UserEdit from './account/UserEdit';
import Course from './course/Course';
import CourseAdd from './course/CourseAdd';
import CourseEdit from './course/CourseEdit';
import CourseInfo from './course/CourseInfo';
import CourseSection from './course/CourseSection';
import CourseSectionEdit from './course/CourseSectionEdit';
import PreCourse from './course/PreCourse';
import CourseCategory from './courseCategory/CourseCategory';
import CourseCategoryAdd from './courseCategory/CourseCategoryAdd';
import CourseCategoryEdit from './courseCategory/CourseCategoryEdit';
import Dashboard from './dashboard/Dashboard';
import Feedback from './feedback/Feedback';
import LearnPath from './learnPath/LearnPath';
import LearnPathAdd from './learnPath/LearnPathAdd';
import LearnPathEdit from './learnPath/LearnPathEdit';
import LearnPathInfo from './learnPath/LearnPathInfo';
import PathSection from './learnPath/PathSection';
import PathSectionEdit from './learnPath/PathSectionEdit';
import Lesson from './lesson/Lesson';
import LessonAdd from './lesson/LessonAdd';
import LessonComment from './lesson/LessonComment';
import LessonCommentEdit from './lesson/LessonCommentEdit';
import LessonEdit from './lesson/LessonEdit';
import LessonInfo from './lesson/LessonInfo';
import Level from './level/Level';
import LevelAdd from './level/LevelAdd';
import LevelEdit from './level/LevelEdit';
import Notification from './notification/Notification';
import NotificationAdd from './notification/NotificationAdd';
import Order from './order/Order';
import OrderAdd from './order/OrderAdd';
import OrderItem from './order/OrderItem';
import OrderItemAdd from './order/OrderItemAdd';
import OrderUpdate from './order/OrderUpdate';
import Slide from './slide/Slide';
import SlideAdd from './slide/SlideAdd';
import SlideEdit from './slide/SlideEdit';
import Support from './supportFeature/Support';
import SupportAdd from './supportFeature/SupportAdd';
import SupportEdit from './supportFeature/SupportEdit';
import Tag from './tag/Tag';
import TagAdd from './tag/TagAdd';
import TagEdit from './tag/TagEdit';
import UserGroup from './userGroup/UserGroup';
import UserGroupAdd from './userGroup/UserGroupAdd';
import UserGroupEdit from './userGroup/UserGroupEdit';
import UserGroupInfo from './userGroup/UserGroupInfo';
import UserGroupInfoAdd from './userGroup/UserGroupInfoAdd';

// import Feedback from './feedback/Feedback';
export const AdminRoutes = [
    {
        path: "/admin",
        exact: true,
        title: 'meta.index.title',
        description: 'meta.index.description',
        main: () => <Dashboard />
    },
    {
        path: "/admin/course",
        exact: true,
        title: 'admin:meta.course.title',
        description: 'admin:meta.course.description',
        main: () => <Course />
    },
    {
        path: "/admin/course/add",
        exact: true,
        title: 'admin:meta.course.title',
        description: 'admin:meta.course.description',
        main: () => <CourseAdd />
    },
    {
        path: "/admin/course/edit/:id",
        title: 'admin:meta.course.title',
        description: 'admin:meta.course.description',
        main: () => <CourseEdit />
    },
    {
        path: "/admin/course/info/:id",
        title: 'admin:meta.course.title',
        description: 'admin:meta.course.description',
        main: () => <CourseInfo />
    },
    {
        path: "/admin/course/section/edit/:id",
        title: 'admin:meta.course.title',
        description: 'admin:meta.course.description',
        main: () => <CourseSectionEdit />
    },
    {
        path: "/admin/course/section/:id",
        title: 'admin:meta.course.title',
        description: 'admin:meta.course.description',
        main: () => <CourseSection />
    },
    {
        path: "/admin/course/add",
        exact: true,
        title: 'admin:meta.course.title',
        description: 'admin:meta.course.description',
        main: () => <CourseAdd />
    },

    {
        path: "/admin/learn-path",
        exact: true,
        title: 'admin:meta.course.title',
        description: 'admin:meta.course.description',
        main: () => <LearnPath />
    },

    {
        path: "/admin/user-group/add",
        exact: true,
        title: 'admin:meta.course.title',
        description: 'admin:meta.course.description',
        main: () => <UserGroupAdd />
    },
    {
        path: "/admin/user-group",
        exact: true,
        title: 'admin:meta.course.title',
        description: 'admin:meta.course.description',
        main: () => <UserGroup />
    },

    {
        path: "/admin/user-group/info/:id",
        exact: true,
        title: 'admin:meta.course.title',
        description: 'admin:meta.course.description',
        main: () => <UserGroupInfo />
    },

    {
        path: "/admin/user-group/info/add",
        exact: true,
        title: 'admin:meta.course.title',
        description: 'admin:meta.course.description',
        main: () => <UserGroupInfoAdd />
    },

    {
        path: "/admin/user-group/edit/:id",
        exact: true,
        title: 'admin:meta.course.title',
        description: 'admin:meta.course.description',
        main: () => <UserGroupEdit />
    },

    {
        path: "/admin/learn-path/add",
        exact: true,
        title: 'admin:meta.course.title',
        description: 'admin:meta.course.description',
        main: () => <LearnPathAdd />
    },

    {
        path: "/admin/learn-path/edit/:id",
        exact: true,
        title: 'admin:meta.course.title',
        description: 'admin:meta.course.description',
        main: () => <LearnPathEdit />
    },
    {
        path: "/admin/learn-path/info/:id",
        exact: true,
        title: 'admin:meta.course.title',
        description: 'admin:meta.course.description',
        main: () => <LearnPathInfo />
    },
    {
        path: "/admin/learn-path-section/edit/:id",
        title: 'admin:meta.course.title',
        description: 'admin:meta.course.description',
        main: () => <PathSectionEdit />
    },
    {
        path: "/admin/learn-path/section/:id",
        title: 'admin:meta.course.title',
        description: 'admin:meta.course.description',
        main: () => <PathSection />
    },
    {
        path: "/admin/category",
        exact: true,
        title: 'admin:meta.courseCategory.title',
        description: 'admin:meta.courseCategory.description',
        main: () => <CourseCategory />
    },
    {
        path: "/admin/category/add",
        exact: true,
        title: 'admin:meta.courseCategory.title',
        description: 'admin:meta.courseCategory.description',
        main: () => <CourseCategoryAdd />
    },
    {
        path: "/admin/category/edit/:id",
        title: 'admin:meta.courseCategory.title',
        description: 'admin:meta.courseCategory.description',
        main: () => <CourseCategoryEdit />
    },

    {
        path: "/admin/tag",
        exact: true,
        title: 'admin:meta.tag.title',
        description: 'admin:meta.tag.description',
        main: () => <Tag />
    },
    {
        path: "/admin/tag/add",
        exact: true,
        title: 'admin:meta.tag.title',
        description: 'admin:meta.tag.description',
        main: () => <TagAdd />
    },
    {
        path: "/admin/tag/edit/:id",
        title: 'admin:meta.tag.title',
        description: 'admin:meta.tag.description',
        main: () => <TagEdit />
    },

    {
        path: "/admin/support",
        exact: true,
        title: 'admin:meta.support.title',
        description: 'admin:meta.support.description',
        main: () => <Support />
    },
    {
        path: "/admin/support/add",
        exact: true,
        title: 'admin:meta.support.title',
        description: 'admin:meta.support.description',
        main: () => <SupportAdd />
    },
    {
        path: "/admin/support/edit/:id",
        title: 'admin:meta.support.title',
        description: 'admin:meta.support.description',
        main: () => <SupportEdit />
    },

    {
        path: "/admin/level",
        exact: true,
        title: 'admin:meta.level.title',
        description: 'admin:meta.level.description',
        main: () => <Level />
    },
    {
        path: "/admin/lever/add",
        exact: true,
        title: 'admin:meta.lever.title',
        description: 'admin:meta.level.description',
        main: () => <LevelAdd />
    },
    {
        path: "/admin/level/edit/:id",
        title: 'admin:meta.level.title',
        description: 'admin:meta.level.description',
        main: () => <LevelEdit />
    },
    //comment
    {
        path: "/admin/lesson-comment/:id",
        exact: true,
        main: () => <LessonComment />
    },
    {
        path: "/admin/comment/edit/:id",
        exact: true,
        main: () => <LessonCommentEdit />
    },
    {
        path: "/admin/lesson",
        exact: true,
        title: 'admin:meta.lesson.title',
        description: 'admin:meta.lesson.description',
        main: () => <Lesson />
    },
    {
        path: "/admin/lesson/add",
        exact: true,
        title: 'admin:meta.lesson.title',
        description: 'admin:meta.lesson.description',
        main: () => <LessonAdd />
    },
    {
        path: "/admin/lesson/info/:id",
        title: 'admin:meta.lesson.title',
        description: 'admin:meta.lesson.description',
        main: () => <LessonInfo />
    },
    {
        path: "/admin/lesson/edit/:id",
        title: 'admin:meta.lesson.title',
        description: 'admin:meta.lesson.description',
        main: () => <LessonEdit />
    },
    {
        path: "/admin/order-item/:id",
        title: 'admin:meta.orderItem.title',
        description: 'admin:meta.orderItem.description',
        main: () => <OrderItem />
    },
    {
        path: "/admin/order-item/add",
        title: 'admin:meta.orderItem.title',
        description: 'admin:meta.orderItem.description',
        main: () => <OrderItemAdd />
    },
    {
        path: "/admin/order",
        exact: true,
        title: 'admin:meta.order.title',
        description: 'admin:meta.order.description',
        main: () => <Order />
    },
    {
        path: "/admin/order/add",
        title: 'admin:meta.order.title',
        description: 'admin:meta.order.description',
        main: () => <OrderAdd />
    },
    {
        path: "/admin/order/edit/:id",
        title: 'admin:meta.order.title',
        description: 'admin:meta.order.description',
        main: () => <OrderUpdate />
    },
    {
        path: "/admin/account",
        exact: true,
        title: 'admin:meta.account.title',
        description: 'admin:meta.account.description',
        main: () => <User />
    },
    {
        path: "/admin/account/add",
        exact: true,
        title: 'admin:meta.account.title',
        description: 'admin:meta.account.description',
        main: () => <UserAdd />
    },
    {
        path: "/admin/account/edit/:id",
        exact: true,
        title: 'admin:meta.account.title',
        description: 'admin:meta.account.description',
        main: () => <UserEdit />
    },
    {
        path: "/admin/slide",
        exact: true,
        title: 'admin:meta.slide.title',
        description: 'admin:meta.slide.description',
        main: () => <Slide />
    },
    {
        path: "/admin/slide/add",
        exact: true,
        title: 'admin:meta.slide.title',
        description: 'admin:meta.slide.description',
        main: () => <SlideAdd />
    },
    {
        path: "/admin/slide/edit/:id",
        exact: true,
        title: 'admin:meta.slide.title',
        description: 'admin:meta.slide.description',
        main: () => <SlideEdit />
    },
    {
        path: "/admin/account/teacher/:id",
        exact: true,
        title: 'admin:meta.account.title',
        description: 'admin:meta.account.description',
        main: () => <UpdateTeacher />
    },
    {
        path: "/admin/notification",
        exact: true,
        title: 'admin:meta.account.title',
        description: 'admin:meta.account.description',
        main: () => <Notification />
    },
    {
        path: "/admin/notification/add",
        exact: true,
        title: 'admin:meta.account.title',
        description: 'admin:meta.account.description',
        main: () => <NotificationAdd />
    },
    {
        path: "/admin/feedback",
        exact: true,
        title: 'meta.feedback.title',
        description: 'admin:meta.feedback.description',
        main: () => <Feedback />
    },
    {
        path: "/admin/pre-course/:id",
        exact: true,
        title: 'meta.precourse.title',
        description: 'admin:meta.precourse.description',
        main: () => <PreCourse />
    },
    //khai bao cac url ben tren * de tranh goi notfound trc
    {
        path: "*",
        title: 'meta.404.title',
        description: 'meta.404.description',
        main: () => <NotFound />
    }
];