import firebase from 'firebase';
import React from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';

import AuthVerification from './AuthVerification';

class Login extends React.Component {
    // Configure FirebaseUI.
    uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // We will display Google and Facebook as auth providers.
        callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: (authResult, redirectUrl) => {
                this.login()
                return false;
            }
        },
        signInOptions: [
            // {
            //     provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
            //     // Invisible reCAPTCHA with image challenge and bottom left badge.
            //     recaptchaParameters: {
            //         type: 'image',
            //         size: 'invisible',
            //         badge: 'bottomleft'
            //     },
            //     defaultCountry: 'VN'
            // },
            {
                provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
                requireDisplayName: true,
                forceSameDevice: true,
                signInMethod: firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
            }
        ],
        tosUrl: '/term-of-use',
        privacyPolicyUrl: "/private-policy"
    }

    login = async () => {
        let { history } = this.props;
        var check = await AuthVerification.authenticate();
        if (check) {
            console.log(history)
            history.replace('/admin');
        } else {
            window.location.reload()
        }
    };

    render() {
        return (
            <div className="login-page">
                <div className="login-box">
                    <div className="login-logo">
                        <img src="/logo-b.png" width="192" alt="J Master" />
                    </div>
                    <div className="card">
                        <div className="card-body login-card-body">
                            <p className="login-box-msg">Sign in to start your session</p>
                            <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(withTranslation()(Login));