import React, { Component, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import AsyncSelect from 'react-select/async';

import API from '../../API';

class OrderAdd extends React.Component {
    render() {
        return (
            <div className="card card-secondary">
                <FormHeader />
                <FormBody {...this.props} />
            </div>
        );
    }
}
const FormHeader = () => {
    const { t } = useTranslation()
    return <div className="card-header">
        <h3 className="card-title">{t('admin:order.add.title')}</h3>
    </div>
}

class FormBody extends Component {
    constructor(props) {
        super(props)
        this.state = {
            customerDTO: null,
            role: "ROLE_MEMBER"
        }
    }


    loadAccountData = async () => {
        var resp = await API.authorizedJSONPost("/api/admin/account/search", this.state);
        if (resp.ok) {
            var json = await resp.json();
            this.setState({ responseData: json })
        }
    }


    loadAccount = async (inputValue) => {
        this.setState({ keyword: inputValue })
        await this.loadAccountData();
        const options = [];
        this.state.responseData.data.forEach(item => {
            options.push({ value: item, label: item.name })
        })
        return options
    };

    submit = async () => {
        var resp = await API.authorizedJSONPost("/api/admin/order/add", this.state);
        if (resp.ok) {
            this.props.loadData()
        }
    }

    render() {
        const { t } = this.props;

        return <form >
            <div className="card-body">
                <div className="form-group">
                    <label className="col-form-label">{t('admin:order.customerName')}</label>
                    <div className="col-md-12">
                        <AsyncSelect
                            cacheOptions
                            loadOptions={this.loadAccount}
                            defaultOptions
                            isClearable={true}
                            isSearchable={true}
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    this.setState({ customerDTO: selectedOption.value })
                                } else {
                                    this.setState({ customerDTO: null })
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="card-footer">
                <button type="button" onClick={this.submit} className="btn btn-info">{t('button.save')}</button>
            </div>
        </form>
    }
}
export default withRouter(withTranslation()(OrderAdd));