import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

class Paging extends Component {
    navigate = async (page, length) => {
        this.props.navigate(page, length ? length : this.props.length)
    }

    render() {
        let { t } = this.props;
        const numbers = []
        const maxPage = Math.ceil(this.props.response.recordsFiltered / this.props.length)
        // for (let i = 0; i < maxPage; i++) {
        //     numbers.push(i + 1)
        // }
        const currentPage = this.props.start / this.props.length + 1;
        
        if (maxPage < 10) {
            for (let i = 0; i < maxPage; i++) {
                numbers.push(i + 1)
            }
        }
        else {
            var step = currentPage / 10
            if (Math.ceil(step) * 10 < maxPage) {
                for (let i = (Math.ceil(step - 1)) * 10; i < Math.ceil(step) * 10; i++) {
                    numbers.push(i + 1)
                }
            }else {
                for (let i = (Math.ceil(step - 1)) * 10; i < maxPage; i++) {
                    numbers.push(i + 1)
                }
            }
        }
        return <div className="card-footer clearfix">
            <ul className="pagination pagination-sm m-0 float-left">
                <li>{this.props.response.recordsFiltered} {t('admin:table.records')}</li>
            </ul>
            <ul className="pagination pagination-sm m-0 float-right">
                <li className="page-item" style={{ marginRight: 10 }}>
                    <select className="page-link" onChange={(event) => this.navigate(currentPage, event.target.value)}>
                        <option value="10">10</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="500">500</option>
                    </select>
                </li>

                {currentPage>1?<li className="page-item"><a className="page-link" href="#" onClick={() => this.navigate(currentPage - 1)}>«</a></li>:null}
                {numbers.map(page => {
                    const classActive = (currentPage === page) ? 'page-item active' : 'page-item'
                    return <li key={page} className={classActive} ><a className="page-link" href="#" onClick={() => this.navigate(page)}>{page}</a></li>
                })}
                {(currentPage < maxPage)?<li className="page-item"><a className="page-link" href="#" onClick={() => this.navigate(currentPage + 1)}>»</a></li>:null}
            </ul>
        </div>
    }
}

export default (withTranslation()(Paging))