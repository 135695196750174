import React, { Component, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { tinyConfig } from '../shared/TinyConfig';
import API from '../../API';
import AlertBlockUI from '../shared/AlertBlockUI';
import { handleError } from '../shared/ResponseHandler';
import { Editor } from '@tinymce/tinymce-react';

class PathSectionAdd extends React.Component {

    render() {
        return (
            <div className="card card-secondary">
                <FormHeader />
                <FormBody {...this.props} />
            </div>
        );
    }
}
const FormHeader = () => {
    const { t } = useTranslation()
    return <div className="card-header">
        <h3 className="card-title">{t('admin:course.courseSection.add.title')}</h3>
    </div>
}

class FormBody extends Component {
    constructor(props) {
        super(props)
        const { match } = this.props;

        var learnPathId = match.params.id;
        this.state = {
            learnPathId: learnPathId,
            name: '',
            description: '',
            sortOrder: 1,
        }
    }

    setPathSectionName = (event) => this.setState({ name: event.target.value })
    setPathSortOrder = (event) => this.setState({ sortOrder: event.target.value })
    setPathSectionDescription = (event) => this.setState({ description: event })

    submit = async () => {
        var resp = await API.authorizedJSONPost("/api/teacher/learn-path-section/add", this.state);
        if (resp.ok) {
            this.setState({ status: "ok" })
            this.props.loadData()
        } else {
            handleError(resp, this)
        }
    }

    render() {
        const { t } = this.props;

        return <form role="form" id="form">
            <div className="card-body">
                <div className="form-group">
                    <label >{t('admin:course.courseSection.courseSectionName')}</label>
                    <input type="text" className="form-control" onChange={this.setPathSectionName}
                        value={this.state.name} placeholder={t('admin:course.courseSection.courseSectionName')} required />
                </div>

                <div className="form-group">
                    <label >{t('admin:course.courseSection.sortOrder')}</label>
                    <input type="text" className="form-control" onChange={this.setPathSortOrder}
                        value={this.state.sortOrder} placeholder={t('admin:course.courseSection.sortOrder')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:learnPath.description')}</label>
                    <Editor apiKey="rhk66ixiw1z5jxhvf5o22ouhtq5l3vh1yj8jq3xzgycsd1hd"
                        initialValue={this.state.description}
                        init={tinyConfig}
                        onEditorChange={this.setPathSectionDescription}
                    />
                </div>
            </div>
            <div className="card-footer">
                <button type="button" onClick={this.submit} className="btn btn-info">{t('button.save')}</button>
            </div>
            <AlertBlockUI {...this.state} setState={(status) => this.setState(status)} />
        </form>
    }
}
export default withRouter(withTranslation()(PathSectionAdd));