import React, { Component } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import getSlug from 'speakingurl';
import API from '../../API';
import AlertBlockUI from '../shared/AlertBlockUI';
import { handleError } from '../shared/ResponseHandler';

class CourseCategoryAdd extends React.Component {
    render() {
        return (
            <div className="card card-secondary">
                <FormHeader />
                <FormBody {...this.props} />
            </div>
        );
    }
}
const FormHeader = () => {
    const { t } = useTranslation()
    return <div className="card-header">
        <h3 className="card-title">{t('admin:courseCategory.add.title')}</h3>
    </div>
}

class FormBody extends Component {
    constructor(props) {
        super(props)
        this.state = {
            categoryName: '',
            description: '',
            sortOrder: '',
            parentCategoryId: null,
            imageFile: null,

        }
    }

    setCourseCategoryName = (event) => this.setState({ categoryName: event.target.value })
    setDescription = (event) => this.setState({ description: event.target.value })
    setSortOrder = (event) => this.setState({ sortOrder: event.target.value })
    setCourseCategoryImageFile = (event) => this.setState({ imageFile: event.target.files[0] })
    loadParentCategoryData = async () => {
        var resp = await API.authorizedJSONPost("/api/admin/category/search", this.state);

        if (resp.ok) {
            var json = await resp.json();
            this.setState({ responseData: json })
        } else {
            handleError(resp, this)
        }
    }

    loadOptions = async (inputValue) => {
        this.setState({ keyword: inputValue })
        await this.loadParentCategoryData();
        const options = [];
        this.state.responseData.data.forEach(item => {
            options.push({ value: item, label: item.categoryName })
        })
        return options
    };
    submit = async () => {
        //upload firebase storage
        const folderImage = 'category/'
        const name = getSlug(this.state.categoryName) + "-" + new Date().getTime()
        const imageUrl = await API.firebaseFileUpload(this.state.imageFile, folderImage + name)
        this.setState({ image: imageUrl }, this.addCourseCategoryToBackend)
    }
    addCourseCategoryToBackend = async () => {
        var resp = await API.authorizedJSONPost("/api/admin/category/add", this.state);
        if (resp.ok) {
            this.setState({ status: "ok" })
            this.props.loadData()
        } else {
            handleError(resp, this)
        }
    }

    render() {
        const { t } = this.props;

        return <form id="form">
            <div className="card-body">
                <div className="form-group">
                    <label >{t('admin:courseCategory.name')}</label>
                    <input type="text" className="form-control" onChange={this.setCourseCategoryName}
                        value={this.state.name} placeholder={t('admin:courseCategory.name')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:courseCategory.sortOrder')}</label>
                    <input type="number" className="form-control" onChange={this.setSortOrder}
                        value={this.state.metaKeyword} placeholder={t('admin:courseCategory.sortOrder')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:courseCategory.image')}</label>
                    <input type="file" name="imageFile" className="form-control-file" onChange={this.setCourseCategoryImageFile}
                        required accept="image/*" />
                </div>
                {/* <div className="form-group">
                    <label className="col-form-label">{t('admin:courseCategory.parentCategory')}</label>
                    <AsyncSelect
                        cacheOptions
                        loadOptions={this.loadOptions}
                        defaultOptions
                        isClearable={true}
                        isSearchable={true}
                        onChange={(selectedOption) => {
                            if (selectedOption) {
                                this.setState({ parentCategoryId: selectedOption.value.id })
                            } else {
                                this.setState({ parentCategoryId: null })
                            }
                        }}
                    />
                </div> */}
                <div className="form-group">
                    <label >{t('admin:courseCategory.description')}</label>
                    <textarea className="form-control" onChange={this.setDescription}
                        value={this.state.metaDescription} required></textarea>
                </div>
            </div>
            <div className="card-footer">
                <button type="button" onClick={this.submit} className="btn btn-info">{t('button.save')}</button>
            </div>
            <AlertBlockUI {...this.state} setState={(status) => this.setState(status)} />
        </form>
    }
}
export default withRouter(withTranslation()(CourseCategoryAdd));