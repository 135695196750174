import React, { Component } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';

import API from '../../API';
import { handleError } from '../shared/ResponseHandler';
import AlertBlockUI from '../shared/AlertBlockUI';
class SupportEdit extends React.Component {

    render() {
        return (<div className="content-wrapper">
            <Breadcrumb />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-secondary">
                                <FormHeader />
                                <FormBody {...this.props} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        )
    }
}

const Breadcrumb = () => {
    const { t } = useTranslation();
    return <section className="content-header">
        <div className="container-fluid">
            <div className="row mb-2">
                <div className="col-sm-6">
                    <h1><Link to="/admin/support"><i className="fas fa-arrow-left fa-sm" /> </Link>  {t('admin:support.edit.title')}</h1>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/admin">Home</Link></li>
                        <li className="breadcrumb-item"><Link to="/admin/support">{t('admin:meta.support.title')}</Link></li>
                        <li className="breadcrumb-item active">{t('admin:support.edit.title')}</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
}

const FormHeader = () => {
    const { t } = useTranslation()
    return <div className="card-header">
        <h3 className="card-title">{t('admin:support.edit.title')}</h3>
    </div>
}

class FormBody extends Component {
    constructor(props) {
        super(props)
        this.state = {
            feature: '',
            icon:''
        }
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        const { match } = this.props;
        var id = match.params.id;
        var resp = await API.authorizedJSONGET("/api/admin/support/" + id);
        if (resp.ok) {
            var tag = await resp.json();
            this.setState(tag);
        } else {
            handleError(resp, this)
        }
    }

  

    setFeature = (event) => this.setState({ feature: event.target.value })
    setIcon = (event) => this.setState({ icon: event.target.value })
    submit = async () => {
        var resp = await API.authorizedJSONPut("/api/admin/support/update", this.state);
        if (resp.ok) {
            this.setState({ status: "ok" })
        } else {
            handleError(resp, this)
        }
    }

    render() {
        const { t } = this.props;

        return <form >
            <div className="card-body">
                <div className="form-group">
                    <label>#</label>
                    <input type="text" className="form-control"
                        value={this.state.id} disabled />
                </div>
                <div className="form-group">
                    <label >{t('admin:support.feature')}</label>
                    <input type="text" className="form-control" onChange={this.setFeature}
                        value={this.state.feature} placeholder={t('admin:support.feature')} />
                </div>
                <div className="form-group">
                    <label >{t('admin:support.icon')}</label>
                    <input type="text" className="form-control" onChange={this.setIcon}
                        value={this.state.icon} placeholder={t('admin:support.icon')} />
                </div>
            </div>
            <div className="card-footer">
                <button type="button" onClick={this.submit} className="btn btn-info">{t('button.save')}</button>
            </div>
            <AlertBlockUI {...this.state} setState={(status) => this.setState(status)} />
        </form>
    }
}

export default withRouter(withTranslation()(SupportEdit));