import React, { Component } from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import AlertBlockUI from '../shared/AlertBlockUI';
import API from '../../API';
import { handleError } from '../shared/ResponseHandler';
import AsyncSelect from 'react-select/async';

class NotificationAdd extends React.Component {

    render() {
        return (
            <div className="card card-secondary">
                <FormHeader />
                <FormBody {...this.props} />
            </div>
        );
    }
}
const FormHeader = () => {
    const { t } = useTranslation()
    return <div className="card-header">
        <h3 className="card-title">{t('admin:notification.add.title')}</h3>
    </div>
}

class FormBody extends Component {
    constructor(props) {
        super(props)
        this.state = {
            title: '',
            content:'',
            toUserId:null
        }
    }
    
    setTitle = (event) => this.setState({ title: event.target.value })
    setContent = (event) => this.setState({ content: event.target.value })


    loadUser = async ()=>{
        var resp = await API.authorizedJSONPost("/api/admin/account/search", this.state);
        if (resp.status == 200) {
            var json = await resp.json();
            this.setState({ responseUserData: json })
        } else {
            handleError(resp, this)
        }
    }

    submit = async () => {
        var resp = await API.authorizedJSONPost("/api/notification/add", this.state);
        if (resp.ok) {
            this.setState({ status: "ok" })
            this.props.loadData()
        } else {
            handleError(resp, this)
        }
    }
    loadUserOptions = async (inputValue) => {
        this.setState({ keyword: inputValue })
        await this.loadUser();
        const options = [];
        this.state.responseUserData.data.forEach(item => {
            options.push({ value: item, label: item.email })
        })
        return options
    };
   
    render() {
        const { t } = this.props;

        return <form >
            <div className="card-body">
                <div className="form-group">
                    <label >{t('admin:notification.title')}</label>
                    <input type="text" className="form-control" onChange={this.setTitle}
                        value={this.state.title} placeholder={t('admin:notification.title')} required />
                </div>
                <div className="form-group">
                    <label >{t('admin:notification.content')}</label>
                    <input type="text" className="form-control" onChange={this.setContent}
                       value={this.state.content} placeholder={t('admin:notification.content')} required />
                </div>
                <div className="form-group">
                    <label className="col-form-label">{t('admin:notification.toUser')}</label>
                    <div className="col-md-12">
                        <AsyncSelect
                            cacheOptions
                            loadOptions={this.loadUserOptions}
                            defaultOptions
                            isClearable={true}
                            isSearchable={true}
                            onChange={(selectedOption) => {
                                if (selectedOption) {
                                    this.setState({ toUserId: selectedOption.value.id  })
                                } else {
                                    this.setState({ toUserId: null })
                                }
                            }}
                        />
                    </div>
                </div>
            </div>
            <div className="card-footer">
                <button type="button" onClick={this.submit} className="btn btn-info">{t('button.save')}</button>
            </div>
            <AlertBlockUI {...this.state} setState={(status) => this.setState(status)} />
        </form>
    }

}

export default withRouter(withTranslation()(NotificationAdd));