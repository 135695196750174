import React, { Component } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import AlertBlockUI from '../shared/AlertBlockUI';
import DeleteDialog from '../shared/DeleteDialog';
import Paging from '../shared/Paging';
import TableToolbar from '../shared/TableToolbar';
import LessonAdd from './LessonAdd';
import API from '../../API';
import { handleError } from '../shared/ResponseHandler';

class Lesson extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            start: 0,
            length: 10,
            keyword: "",
            sortBy: {
                'column': 'id',
                'dir': 'desc'
            },
            response: { recordsTotal: 0, recordsFiltered: 0, data: [] }
        }
    }

    componentDidMount() {
        this.loadData()
    }

    loadData = async () => {
        var resp = await API.authorizedJSONPost("/api/teacher/lesson/search", this.state);

        if (resp.ok) {
            var json = await resp.json();
            this.setState({ response: json })
        } else {
            handleError(resp, this)
        }
    }

    search = async (keyword) => {
        this.setState({ keyword, start: 0 }, this.loadData)
    }

    navigate = async (page, length) => {
        if (page < 1) {
            page = 1
        }
        const nextStart = (page - 1) * this.state.length
        this.setState({ start: nextStart, length }, this.loadData)
    }

    deleteItem = async (ids, imgs) => {
        var resp = await API.authorizedJSONPost("/api/teacher/lesson/delete", ids);
        if (resp.ok) {
            const unableDeletedIds = await resp.json()
            if (unableDeletedIds.length == 0) {
                await API.firebaseFileDelete(decodeURIComponent(imgs[0].substring(imgs[0].indexOf("lesson"), imgs[0].indexOf("?"))))
            }
            this.setState({ status: "ok" })
            this.loadData()
        } else {
            handleError(resp, this)
        }
    }

    render() {
        let { t } = this.props;
        return (
            <div className="content-wrapper">
                <Breadcrumb />
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-sm-6">
                                <LessonAdd loadData={this.loadData} />
                            </div>
                            <div className="col-12 col-sm-6">
                                <div className="card card-secondary">
                                    <TableToolbar search={this.search} title={t('admin:meta.lesson.title')} />

                                    <DataTable {...this.props} {...this.state} deleteItem={this.deleteItem} />

                                    <Paging {...this.props} {...this.state} navigate={this.navigate} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <AlertBlockUI {...this.state} setState={(status) => this.setState(status)} />
            </div>
        )
    }
}
const Breadcrumb = () => {
    const { t } = useTranslation();
    return <section className="content-header">
        <div className="container-fluid">
            <div className="row mb-2">
                <div className="col-sm-6">
                    <h1>{t('admin:meta.lesson.title')}</h1>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                        <li className="breadcrumb-item"><Link to="/admin">Home</Link></li>
                        <li className="breadcrumb-item active">{t('admin:meta.lesson.title')}</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
}

class DataTable extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedDeletionItemId: null
        }
    }

    render() {
        let { t } = this.props;
        return <div className="card-body table-responsive p-0">
            <table className="table table-hover text-nowrap">
                <thead>
                    <tr>
                        <th>#</th>
                        <th >{t('admin:lesson.name')}</th>
                        <th >{t('admin:lesson.imageUrl')}</th>
                        <th>{t('admin:button.action')}</th>
                    </tr>
                </thead>
                <tbody >
                    {this.props.response.data.map((item) => {
                        return <tr key={item.id}>
                            <td className="align-middle">{item.id} <br /> <span className="badge badge-primary">{item.type}</span> <br /> <span className="badge badge-info">{item.timeAmount}m</span> {item.isPublic == true ? <span className="badge badge-success">public</span> : <span className="badge badge-danger">private</span>}</td>
                            <td className="align-middle">{item.name} </td>
                            <td className="align-middle"> <img src={item.imageUrl} className="img-thumbnail" width="150" /> </td>
                            <td className="align-middle">
                                <div>
                                    <Link className="btn btn-success btn-sm" to={'/admin/lesson/info/' + item.id}><i className="fas fa-eye">
                                    </i> {t('button.info')} </Link>
                                    <Link className="btn btn-info btn-sm" to={'/admin/lesson/edit/' + item.id}><i className="fas fa-pencil-alt">
                                    </i> {t('button.edit')} </Link>
                                    <Link className="btn btn-info btn-sm" to={'/admin/lesson-comment/' + item.id}><i className="fas fa-pencil-alt">
                                    </i> {t('button.comment')} </Link>
                                    <button type="button" onClick={() => this.setState({ selectedDeletionItemId: item.id, selectedImageUrl: item.imageUrl })} className="btn btn-danger btn-sm" data-toggle="modal" data-target="#modal-delete">
                                        <i className="fas fa-trash" /> {t('button.delete')}
                                    </button>
                                </div>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
            <DeleteDialog {...this.state} {...this.props} />
        </div>
    } x
}
export default withRouter(withTranslation()(Lesson));